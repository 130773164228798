import axios from "axios";

const API = (token) => axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    headers: { Authorization: token },
});


//create subscription session
export const createSession = async (body) => {
    try {
        const token = localStorage.getItem("userToken");
        const { data } = await API(token).post("/api/subscription/create-checkout-session", body);
        return data;
    } catch (error) {
        console.log(error.response.data);
        return error.response.data;
    }
}

export const confirmPayment = async (body) => {
    try {
        const token = localStorage.getItem("userToken");
        const { data } = await API(token).post("/api/subscription/confirm-payment", body);
        return data;
    } catch (error) {
        console.log(error.response.data);
        return error.response.data;
    }
}