import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Button, Card, CardBody, Col } from "reactstrap"
import { createSession } from "services/subscription"
import { useSelector } from "react-redux"

const CardPricing = props => {
  const currentUser = useSelector(state => state.auth.currentUser)
  const [email, setEmail] = useState("")

  const handleSubscription = async plan => {
    try {
      const data = {
        plan: plan,
        email: email || "",
      }

      if (!email) {
        alert("Please enter email to subscribe")
        return
      }

      const res = await createSession(data);

      console.log(res);
      if(res.data) {
        window.location.href = res.data.url;
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (currentUser) {
      setEmail(currentUser.email)
    }
  }, [currentUser])

  return (
    <React.Fragment>
      <Col xl="3" md="4">
        <Card className="plan-box">
          <CardBody className="p-4">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h5>{props.pricing.title}</h5>
                <p className="text-muted">{props.pricing.description}</p>
              </div>
              <div className="flex-shrink-0 ms-3">
                <i
                  className={"bx " + props.pricing.icon + " h1 text-primary"}
                  style={{ lineHeight: 1 }}
                />
              </div>
            </div>
            <div className="py-4">
              <h2>
                {" "}
                {props.pricing.price}{" "}
                <span className="font-size-15">{props.pricing.duration}</span>
              </h2>
            </div>
            <div className="text-center plan-btn">
              <button
                onClick={() => handleSubscription(props.pricing.plan)}
                className="btn btn-primary btn-md waves-effect waves-light "
              >
                Buy Now
              </button>
            </div>

            <div className="plan-features mt-5">
              {props.pricing.features.map((feature, key) => (
                <p key={"_feature_" + key}>
                  <i className="bx bx-checkbox-square text-primary me-2" />{" "}
                  {feature.title}
                </p>
              ))}
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

CardPricing.propTypes = {
  pricing: PropTypes.object,
}

export default CardPricing
