import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    Input,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import {withTranslation} from "react-i18next"

//redux
import {useSelector, useDispatch} from "react-redux"
import {handleSearchData} from "../../components/Common/searchFile"
import Select from "react-select"
import {Link} from "react-router-dom"
import {CreateModal} from "./createModal";
import {fetchAllRooms, removeRoom} from "../../services/room";
import {show} from "../../redux/reducers/notification";
import Spinner from "../../components/Common/Spinner";
import {timeFormatter} from "../../common/formater";
import {getBots, getAllParticipants} from "../../services/admin";

const Dashboard = props => {
    //meta title
    document.title = "Dashboard"

    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.auth.currentUser);

    const [isModal, setIsModal] = useState(false);
    const [rooms, setRooms] = useState([]);
    const [bots, setBots] = useState([]);
    const [participants, setParticipants] = useState([]);
    const [isLoading, setIsLoading] = useState(true);


    const options = [
        {value: "Finished", label: "Finished"},
        {value: "Live", label: "Live"},
        {value: "Not Started", label: "Not Started"}
    ]

    const handleOpenModal = () => {
        setIsModal(true);
    }
    // search
    const handleSearchTable = (keyWord) => {
        handleSearchData({setState: setRooms, data: rooms, item: keyWord})
    }

    const handleRemove = async (id) => {
        try {
            const res = await removeRoom({roomId: id});
            dispatch(show({title: res.message, body: "", type: res.status}));
            await getAllRooms();

        } catch (e) {
            dispatch(show({title: "Remove Table Failed!", body: "", type: 'error'}));
        }
    }

    const getAllRooms = async () => {
        try {
            const res = await fetchAllRooms();
            setRooms(res);
            console.log('rooms', res);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    const getAllBots = async () => {
        const res = await getBots();
        if (res) {
            console.log('bots', res.data)
            setBots(res.data || []);
        }
    }

    const getParticipants = async () => {
        const res = await getAllParticipants();
        if (res) {
            console.log('participants', res.data)
            setParticipants(res.data || []);
        }
    }

    useEffect(() => {
        getAllRooms();
        getAllBots();
        getParticipants();
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs
                        title={"Roundtable"}
                        breadcrumbItem={"Dashboard"}
                    />
                    {isLoading ? <Spinner/> :
                        <>
                            <Row>
                                <Col lg={12}>
                                    <Card className={"job-filter"}>
                                        <CardBody>
                                            <Row className="d-flex justify-content-between g-3">
                                                <Col xxl={8} lg={6}>
                                                    <div className="d-flex justify-content-between gap-3">
                                                        <Input type={"text"} id={"searchName"} autoComplete={"off"}
                                                               placeholder={"Search by name"}/>
                                                        <button onClick={() => handleSearchTable(document.getElementById("searchName").value)}
                                                            type="button" className="btn btn-info h-100">
                                                            <i className="bx bx-search-alt align-middle"/>
                                                        </button>
                                                    </div>
                                                </Col>
                                                <Col xxl={2} lg={4} sm={12}>
                                                    <div className="d-flex justify-content-end ">
                                                        <button onClick={() => handleOpenModal()}
                                                                className="btn btn-primary w-100">
                                                            <i className="bx bx-list-plus align-middle"/>
                                                            Create Table
                                                        </button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            {/* Roundtable list */}
                            <Row>
                                {
                                    rooms.length > 0 ?
                                        rooms.map((room, index) => (

                                            <Col xl={3} key={index}>
                                                <Card>
                                                    <CardBody>
                                                        <div className="d-flex align-start mb-3 "
                                                             style={{height: '26px'}}>
                                                            <div className="flex-grow-1">

                                                                {currentUser?.email === room?.groupAdmin.email ?
                                                                    <span className={"badge badge-soft-success"}>
                                                                            Free
                                                                        </span>
                                                                    : null
                                                                }

                                                            </div>
                                                            {currentUser?.email === room?.groupAdmin.email ?
                                                                <button type="button"
                                                                        className="btn btn-danger btn-sm like-btn"
                                                                        onClick={() => handleRemove(room._id)}>
                                                                    <i className="bx bx-trash"/>
                                                                </button> :
                                                                null
                                                            }
                                                        </div>
                                                        <div className="text-center mb-3">
                                                            <img src={room.photo} alt=""
                                                                 className="avatar-sm rounded-circle"/>
                                                            <h6 className="font-size-15 mt-3 mb-1">{room.roomName}</h6>
                                                            <p className="mb-0 text-muted">{room.context}</p>
                                                        </div>
                                                        <div
                                                            className="d-flex mb-3 justify-content-center gap-2 text-muted">
                                                            <div>
                                                                <i className='bx bx-cloud align-middle text-primary'/> OpenAI
                                                            </div>
                                                            <p className="mb-0 text-center">
                                                                <i className='bx bx-money align-middle text-primary'/>
                                                                $1/ word
                                                            </p>
                                                        </div>
                                                        <div className="hstack gap-2 justify-content-center">
                                                            <span
                                                                className="badge badge-soft-success">{timeFormatter(room.updatedAt)}</span>
                                                        </div>

                                                        <div className="mt-4 pt-1">
                                                            <Link to={`/rooms/${room._id}`}
                                                                  className="btn btn-soft-primary d-block">
                                                                Join to Table
                                                            </Link>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>

                                        )) :
                                        <div className="text-center">
                                            No Rooms Found, please create one.
                                        </div>
                                }
                            </Row>


                            <CreateModal isModal={isModal} setIsModal={setIsModal} getAllRooms={getAllRooms} bots={bots} participants={participants}/>
                        </>
                    }
                    {/* Filter */}

                </Container>

            </div>
        </React.Fragment>
    )
}

Dashboard.propTypes = {
    t: PropTypes.any,
}

export default withTranslation()(Dashboard)
