export const handleSearchData = ({setState, data, item}) => {
    console.log(item === "");
    if (item === "") {
        setState(data.filter((search) =>
            Object.values(search).some(
                (field) =>
                    true,
            )
        ));
    } else {
        setState(data.filter((search) =>
            Object.values(search).some(
                (field) =>
                    (typeof field === 'string' &&
                        field?.toLowerCase().includes(item?.toLowerCase())),
            )
        ))
    }
}

export const handleCheckBanWord = async (banWords, curMsg) => {
    let result = banWords.filter((banWord) => {
        if (curMsg?.toLowerCase().includes(banWord?.toLowerCase())) {
            return true;
        }
    });
    return result;
}