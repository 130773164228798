import React, {useState} from "react";
import PropTypes from 'prop-types';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";

//i18n
import {withTranslation} from "react-i18next";

// users
import user1 from "../../../assets/images/users/avatar-blank.jpeg";
import {useSelector} from "react-redux";

const ProfileMenu = props => {
    // Declare a new state variable, which we'll call "menu"
    const [menu, setMenu] = useState(false);

    const currentUser = useSelector((state) => state.auth.currentUser);

    const handleLogout = () => {
        localStorage.removeItem("userToken");
        window.location.href = "/login";
    }

    return (
        <React.Fragment>
            <Dropdown
                isOpen={menu}
                toggle={() => setMenu(!menu)}
                className="d-inline-block"
            >
                <DropdownToggle
                    className="btn header-item "
                    id="page-header-user-dropdown"
                    tag="button"
                >
                    <img
                        className="rounded-circle header-profile-user"
                        src={currentUser.pic ? currentUser.pic : user1}
                        alt="Header Avatar"
                    />
                    <span className="d-none d-xl-inline-block ms-2 me-1">{currentUser?.username}</span>
                    <i className="mdi mdi-chevron-down d-none d-xl-inline-block"/>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    {currentUser.isAdmin &&
                    <DropdownItem tag="a" href="/dashboard">
                        {" "}
                        <i className="bx bx-log-in-circle font-size-16 align-middle me-1 text-success"/>
                        {props.t("View as User")}{" "}
                    </DropdownItem>
                    }
                    <div className="dropdown-divider"/>
                    <DropdownItem tag="a" href="/profile">
                        {" "}
                        <i className="bx bx-user font-size-16 align-middle me-1"/>
                        {props.t("Profile")}{" "}
                    </DropdownItem>
                    <DropdownItem tag="a" href={`${currentUser.isAdmin ? '/admin/setting':'/setting'}`}>
                        <span className="badge bg-success float-end">11</span>
                        <i className="bx bx-wrench font-size-16 align-middle me-1"/>
                        {props.t("Settings")}
                    </DropdownItem>
                    <div className="dropdown-divider"/>
                    <button onClick={() => handleLogout()} className="dropdown-item">
                        <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"/>
                        <span>{props.t("Logout")}</span>
                    </button>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

ProfileMenu.propTypes = {
    success: PropTypes.any,
    t: PropTypes.any
};

export default (withTranslation()(ProfileMenu));
