import React from "react";
import {Navigate} from "react-router-dom";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

// Pages Component
import Chat from "../pages/Chat/Chat";
import PagesPricing from "../pages/Subscription/page-pricing";
import AdminDashboard from "../pages/Admin/Dashboard/index";
import {Setting} from "../pages/Admin/Setting";
import Users from "../pages/Admin/User";
import MyPlan from "pages/Subscription/MyPlan";

const authProtectedRoutes = [
    {path: "/dashboard", component: <Dashboard/>},
    // //Profile
    {path: "/profile", component: <UserProfile/>},
    // //Chat
    {path: "/rooms/:roomId", component: <Chat/>},

    //subscription
    {path: "/my-plan", component: <MyPlan/>},
    {path: "/pricing", component: <PagesPricing/>},

    // this route should be at the end of all other routes
    // eslint-disable-next-line react/display-name
    {
        path: "/",
        exact: true,
        component: <Navigate to="/dashboard"/>,
    },

    {path: "*", component: <Navigate to="/dashboard"/>},
];

const authAdminProtectedRoutes = [
    {path: "/admin", component: <AdminDashboard/>},
    {path: "/admin/users", component: <Users/>},
    {path: "/admin/setting", component: <Setting/>},
    // //Profile
    {path: "/admin/*", component:<Navigate to="/admin"/>},
];


const publicRoutes = [
    {path: "/login", component: <Login/>},
    {path: "/logout", component: <Logout/>},
    {path: "/forgot-password", component: <ForgetPwd/>},
    {path: "/register", component: <Register/>},
];

export {authProtectedRoutes, publicRoutes, authAdminProtectedRoutes};
