import React, {useEffect} from "react"
import PropTypes from "prop-types"
import {useDispatch, useSelector} from "react-redux"
import {Routes, Route} from "react-router-dom"
import {layoutTypes} from "./constants/layout"
import "./App.css"
// Import Routes all
import {authProtectedRoutes, publicRoutes, authAdminProtectedRoutes} from "./routes"

// Import all middleware
import {AuthMiddleware, AdminMiddleWare} from "./routes/route"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import VerticalAdminLayout from "./components/VerticalAdminLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"

import fakeBackend from "./helpers/AuthType/fakeBackend"
import {validUser} from "./services/auth";
import {setCurrentUser} from "./redux/reducers/auth";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const getLayout = (layoutType) => {
    let Layout = VerticalLayout
    switch (layoutType) {
        case layoutTypes.VERTICAL:
            Layout = VerticalLayout
            break
        default:
            break
    }
    return Layout
}

const getAdminLayout = (layoutType) => {
    let Layout = VerticalAdminLayout
    switch (layoutType) {
        case layoutTypes.VERTICAL:
            Layout = VerticalAdminLayout
            break
        default:
            break
    }
    return Layout
}



const App = () => {
    const dispatch = useDispatch();

    const {layoutType} = useSelector((state) => state.layout);

    const Layout = getLayout(layoutType);
    const AdminLayout = getAdminLayout(layoutType);

    const currentUser = useSelector((state) => state.auth.currentUser);

    useEffect(() => {
        const isValid = async () => {
            const data = await validUser()

            const user = {
                id: data?.user?._id,
                email: data?.user?.email,
                pic: data?.user?.profilePic,
                bio: data?.user?.bio,
                username: data?.user?.name,
                isAdmin: data?.user?.isAdmin,
                tokens: data?.user?.tokens || 0,
            }

            dispatch(setCurrentUser(user))
        }
        isValid()
    },[dispatch, currentUser]);

    return (
        <React.Fragment>
            <ToastContainer autoClose={3000} closeOnClick theme={"light"}/>
            <Routes>
                {publicRoutes.map((route, idx) => (
                    <Route
                        path={route.path}
                        element={
                            <NonAuthLayout>
                                {route.component}
                            </NonAuthLayout>
                        }
                        key={idx}
                        exact={true}
                    />
                ))}

                {authProtectedRoutes.map((route, idx) => (
                    <Route
                        path={route.path}
                        element={
                            <AuthMiddleware>
                                <Layout>{route.component}</Layout>
                            </AuthMiddleware>}
                        key={idx}
                        exact={true}
                    />
                ))}

                {authAdminProtectedRoutes.map((route, idx) => (
                    <Route
                        path={route.path}
                        element={
                            <AdminMiddleWare>
                                <AdminLayout>{route.component}</AdminLayout>
                            </AdminMiddleWare>}
                        key={idx}
                        exact={true}
                    />
                ))}
            </Routes>
        </React.Fragment>
    )
}

App.propTypes = {
    layout: PropTypes.any
}

export default App