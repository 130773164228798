import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {validAdmin, validUser} from "../services/auth";

const AuthMiddleware = (props) => {
    const pageRoute = useNavigate();
    useEffect(() => {
        const isValid = async () => {
            const data = await validUser();
            if (!data?.user) {
                pageRoute("/login");
            }
        }
        isValid();
    }, [pageRoute]);

    return (<React.Fragment>
        {props.children}
    </React.Fragment>);
};

const AdminMiddleWare = (props) => {
    const pageRoute = useNavigate();
    useEffect(() => {
        const isValid = async () => {
            const data = await validAdmin();
            if (!data?.user?.isAdmin) {
                pageRoute("/login");
            }
        }
        isValid();
    }, [pageRoute]);

    return (<React.Fragment>
        {props.children}
    </React.Fragment>);
};


export {AuthMiddleware, AdminMiddleWare};
