import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import withRouter from './Common/withRouter';
import {useDispatch, useSelector} from 'react-redux';
import {changeLayoutMode} from "../redux/reducers/layout";

const NonAuthLayout = (props) => {
    const dispatch = useDispatch();

    const {layoutModeType} = useSelector(state => state.layout);

    useEffect(() => {
        if (layoutModeType) {
            dispatch(changeLayoutMode(layoutModeType));
        }
    }, [layoutModeType, dispatch]);

    return (
        <React.Fragment>{props.children}</React.Fragment>
    );
};

NonAuthLayout.propTypes = {
    children: PropTypes.any,
    location: PropTypes.object
};

export default withRouter(NonAuthLayout);
