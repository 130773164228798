import {Button, Card, CardBody, Col, Form, Input, Row} from "reactstrap";
import React, {useEffect, useState} from "react";
import {updateBannedWords} from "../../../services/admin";
import {useDispatch} from "react-redux";
import {show} from "../../../redux/reducers/notification";
import {fetchAllBannedWords} from "../../../services/message";

export const BannedWords = () => {
    const dispatch = useDispatch();
    const [banWords, setBanWords] = useState('');
    const [bannedWords, setBannedWords] = useState('');

    const handleBanWords = async () => {
        const res = await updateBannedWords({words: banWords});
        console.log('res', res);
        if(res.status === 'success') {
            dispatch(show({message: 'Banned words updated successfully', title: 'Success', type: 'success'}));
        }
    }

    const fetchAllBanWords = async () => {
        const res = await fetchAllBannedWords();
        console.log('data', res);
        setBannedWords(res.data?.[0].words || '');
    }

    useEffect(() => {
        fetchAllBanWords();
    }, [])

    return (
        <Row>
            <Col xl="12">
                <Card>
                    <CardBody>
                        <h6 className="mb-4 card-title">Banned Words</h6>
                        <Form className="outer-repeater"
                              onSubmit={
                                  (e) => {
                                      e.preventDefault();
                                      handleBanWords(e);
                                  }
                              }>
                            <div data-repeater-list="outer-group" className="outer">
                                <div data-repeater-item className="outer">
                                    <div className="mb-3">
                                        <Input
                                            onChange={(e) => {
                                                setBanWords(bannedWords + "," + e.target.value);
                                            }}
                                            type="textarea"
                                            id="formmessage"
                                            className="form-control"
                                            rows="3"
                                            value={banWords}
                                            placeholder="Enter words separately by ','"
                                        />
                                    </div>
                                    <div className="d-flex mb-3">
                                        Banned Words: <span className={'text-danger'} >{' '}{bannedWords}</span>
                                    </div>
                                    <Button type="submit" color="primary">
                                        Add Words
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}