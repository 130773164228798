import React, {useEffect, useState} from "react";
import {Button, Card, CardBody, Col, Collapse, Form, Input, Label, Row, FormText} from "reactstrap";
import {deleteBot, getBots, updateBot} from "../../../services/admin";
import {show} from "../../../redux/reducers/notification";
import {useDispatch} from "react-redux";

export const BotConfig = () => {
    const dispatch = useDispatch();

    const [botFormData, setBotFormData] = useState({
        name: '',
        endpoint: '',
        key: '',
    });
    const [isOpen, setIsOpen] = useState(false);
    const [isBotOpen, setIsBotOpen] = useState(0);
    const [bots, setBots] = useState([]);

    const toggle = () => setIsOpen(!isOpen);

    const handleFormChange = (e) => {
        setBotFormData({
            ...botFormData,
            [e.target.name]: e.target.value
        })
    }

    const handleBotConfig = async () => {
        try {
            const res = await updateBot(botFormData);
            console.log('res', res);
            dispatch(show({title: res.message, body: "", type: res.status}));
            if (res.status === 'success') {
                await fetchConfigs();
                toggle();
            }
        } catch (e) {
            console.log(e);
            dispatch(show({title: 'Something went wrong', body: "", type: 'error'}));
        }
    }

    const removeBot = async (botId) => {
        try {
            const res = await deleteBot(botId);
            console.log('res', res);
            if (res.status === 'success') {
                await fetchConfigs();
                dispatch(show({title: res.message, body: "", type: res.status}));
            }
        } catch (e) {
            console.log(e.response.data);
            dispatch(show({title: e.response.data.message, body: "", type: 'error'}));
        }

    }

    const fetchConfigs = async () => {
        const res = await getBots();
        if (res) {
            console.log(res);
            setBots(res.data);
        }
    }

    useEffect(() => {
        fetchConfigs();
    }, []);


    return (
        <React.Fragment>
            <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <h4 className="card-title mb-1 d-flex justify-content-between align-items-center">
                                Bot Config
                                <a href="#" onClick={toggle} className="btn btn-outline-success">
                                    <i className="bx bx-plus-circle align-middle"/> Add New
                                </a>
                            </h4>
                            <div className="wizard clearfix">
                                <div className={`${isOpen && 'border'} p-2 `}>
                                    <Collapse isOpen={isOpen} id="betConfig-collapse">
                                        <Form onSubmit={(e) => {
                                            e.preventDefault();
                                            handleBotConfig()
                                        }}>
                                            <Row>
                                                <Col lg="6">
                                                    <div className="mb-3">
                                                        <Label for="bot-name">
                                                            Bot Name
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="bot-name"
                                                            name="name"
                                                            placeholder="Enter Bot Name"
                                                            onChange={(e) => {
                                                                handleFormChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg="6">
                                                    <div className="mb-3">
                                                        <Label for="api-key">
                                                            API KEY
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="api-key"
                                                            name="key"
                                                            placeholder="Enter Your API KEY"
                                                            onChange={(e) => {
                                                                handleFormChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="12">
                                                    <div className="mb-3">
                                                        <Label for="endpoint-url">
                                                            Endpoint URL
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="endpoint-url"
                                                            name="endpoint"
                                                            placeholder="Enter Site Endpoint URL"
                                                            onChange={(e) => {
                                                                handleFormChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg="12">
                                                    <div className="mb-3">
                                                        <Label for="description">
                                                            Description
                                                        </Label>
                                                        <textarea
                                                            className="form-control"
                                                            id="description"
                                                            name="description"
                                                            rows={3}
                                                            placeholder="Description here..."
                                                            onChange={(e) => {
                                                                handleFormChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg="12">
                                                    <div className="">
                                                        <Button type={"submit"} color="primary">
                                                            Create
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Collapse>
                                </div>
                            </div>
                            <Row>
                                <Col lg="12">
                                    <ul className="list-unstyled mb-0">
                                        {bots && bots.map((bot, index) => (
                                            <li key={index} className="d-flex justify-content-between align-items-center border rounded-pill px-4 py-1 mt-2">
                                                <div>
                                                    <h5 className="mb-0">{bot.name}</h5>
                                                </div>
                                                <div>
                                                    <p className="mb-0">{bot.key}</p>
                                                </div>
                                                <div className={''}>
                                                    <a className="btn btn-outline-danger btn-sm mx-1" onClick={() => removeBot(bot._id)}>
                                                        <i className="bx bx-trash-alt mr-1"/>
                                                    </a>
                                                </div>
                                            </li>
                                        ))}

                                    </ul>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}