import React, {useEffect, useState} from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Input, NavItem, NavLink, TabContent, TabPane, Form, Row, Col, Label, Nav,

} from "reactstrap";
// Import Editor
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import {createRoom} from "../../services/room";
import {useDispatch} from "react-redux";
import {show} from "../../redux/reducers/notification";
import {Link} from "react-router-dom";
import classnames from "classnames";

import avatar1 from "../../assets/images/users/avatar-1.jpg";

export const CreateModal = ({isModal, setIsModal, getAllRooms, bots, participants}) => {
    const dispatch = useDispatch();

    const [tableData, setTableData] = useState({
        context: '',
        roomName: '',
        isGroup: true,
        participants: [],
        bots: [],
    });

    const [activeTab, setactiveTab] = useState(1)
    const [passedSteps, setPassedSteps] = useState([1])
    const [activeParticipants, setActiveParticipants] = useState([]);
    const [verticalActiveTab, setVerticalActiveTab] = useState(participants?.[0]?._id);

    const handleTableData = (e) => {
        setTableData({...tableData, [e.target.id]: e.target.value})
    }
    const handleCreateTable = async (e) => {
        e.preventDefault();
        if (tableData.participants?.length < 1) {
            dispatch(show({title: "Please add at least one participant", body: "", type: 'error'}));
            return;
        }
        const res = await createRoom(tableData);
        if (res.message) {
            dispatch(show({title: res.message, body: "", type: 'error'}));
        } else {
            dispatch(show({title: "Create New Table Success!", body: "", type: 'success'}));
            setIsModal(!isModal);
            await getAllRooms();
        }
    }

    const handleAddParticipant = (_id) => {
        if (!activeParticipants.includes(_id)) {
            setActiveParticipants([...activeParticipants, _id]);
        }
    }

    const handleRemoveParticipant = (_id) => {
        let modifiedParticipants = activeParticipants.filter((participant) => participant !== _id);
        setActiveParticipants(modifiedParticipants);
    }

    function toggleTab(tab) {
        if (activeTab !== tab) {
            let modifiedSteps = [...passedSteps, tab]
            if (tab >= 1 && tab <= 4) {
                setactiveTab(tab)
                setPassedSteps(modifiedSteps)
            }
        }
    }

    const toggleVertical = tab => {
        if (verticalActiveTab !== tab) {
            setVerticalActiveTab(tab);
        }
    };

    useEffect(() => {
        setTableData({...tableData, participants: activeParticipants})
    },[activeParticipants])

    useEffect(() => {
        setVerticalActiveTab(participants?.[0]?._id);
    },[participants])

    return (
        <React.Fragment>
            <Modal
                isOpen={isModal}
                role="dialog"
                autoFocus={true}
                centered={true}
                className="exampleModal"
                tabIndex="-1"
                toggle={() => {
                    setIsModal(!isModal)
                }}
            >
                <div className="modal-content">
                    <ModalHeader
                        toggle={() => {
                            setIsModal(!isModal)
                        }}
                    >
                        New Roundtable
                    </ModalHeader>
                    <ModalBody>
                        {/*<form>*/}
                        {/*    <div className="mb-3">*/}
                        {/*        <Input type="Table Name" id={'roomName'} className="form-control" placeholder="Roundtable Name" onChange={(e) => handleTableData(e)}/>*/}
                        {/*    </div>*/}

                        {/*    <div className="mb-3">*/}
                        {/*        <Input type="textarea" id={'context'}  className="form-control" placeholder="Goal of this table..." onChange={(e) => handleTableData(e)}/>*/}
                        {/*    </div>*/}
                        {/*</form>*/}
                        <div className={"wizard clearfix"}>
                            <div className="steps clearfix">
                                <ul>
                                    <NavItem
                                        className={classnames({current: activeTab === 1})}
                                    >
                                        <NavLink
                                            className={classnames({current: activeTab === 1})}
                                            onClick={() => {
                                                setactiveTab(1)
                                            }}
                                            disabled={!(passedSteps || []).includes(1)}
                                        >
                                            <span className="number">1.</span> Set table
                                        </NavLink>
                                    </NavItem>
                                    <NavItem
                                        className={classnames({current: activeTab === 2})}
                                    >
                                        <NavLink
                                            className={classnames({active: activeTab === 2})}
                                            onClick={() => {
                                                setactiveTab(2)
                                            }}
                                            disabled={!(passedSteps || []).includes(2)}
                                        >
                                            <span className="number">2.</span> Choose Agents
                                        </NavLink>
                                    </NavItem>
                                </ul>
                            </div>
                            <div className="content clearfix">
                                <TabContent activeTab={activeTab} className="body">
                                    <TabPane tabId={1}>
                                        <Form>
                                            <Row>
                                                <Col lg="12">
                                                    <div className="mb-3">
                                                        <Label for="roomName">
                                                            Roundtable Name
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="roomName"
                                                            placeholder="Enter Table Name"
                                                            onChange={(e) => handleTableData(e)}
                                                        />
                                                        <Input type={"hidden"} className={"form-control"} id={"participants"} value={activeParticipants}/>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="12">
                                                    <div className="mb-3">
                                                        <Label for="context">
                                                            Goal
                                                        </Label>
                                                        <textarea
                                                            id="context"
                                                            className="form-control"
                                                            rows="2"
                                                            placeholder="Enter Table Goal"
                                                            onChange={(e) => handleTableData(e)}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </TabPane>
                                    <TabPane tabId={2}>
                                        <div>
                                            <Form>
                                                <Row>
                                                    <Col md="3">
                                                        <Nav pills className="flex-column">
                                                            {participants?.map((participant, index) => (
                                                                <NavItem key={index}>
                                                                    <NavLink
                                                                        style={{cursor: "pointer"}}
                                                                        className={classnames({
                                                                            "mb-0": true,
                                                                            "px-2": true,
                                                                            "py-0": true,
                                                                            active: verticalActiveTab === participant._id,
                                                                        })}
                                                                        onClick={() => {
                                                                            toggleVertical(participant._id);
                                                                        }}
                                                                    >
                                                                        {participant.name}
                                                                    </NavLink>
                                                                </NavItem>
                                                            ))}
                                                        </Nav>
                                                        <div className={"mt-4"}>
                                                            <Button
                                                                onClick={() => {
                                                                    handleAddParticipant(verticalActiveTab);
                                                                }}
                                                                type={"button"} className={"btn btn-success w-100"}>
                                                                Add Agents
                                                            </Button>
                                                        </div>
                                                        <div className={"mt-2"}>
                                                            <p> In Rooms:</p>
                                                            <ul className={"list-unstyled"}>
                                                                {activeParticipants.map((participant, index) => (
                                                                    <li key={index}>
                                                                        <div
                                                                            className="d-flex align-items-center font-size-12">
                                                                            {participants?.find((b) => b._id === participant).name}
                                                                            <div className="ms-auto">
                                                                                <a
                                                                                    onClick={() => {
                                                                                        handleRemoveParticipant(participant);
                                                                                    }}
                                                                                    type={"button"}
                                                                                    className={""}
                                                                                >
                                                                                    <i className={'bx bx-trash text-danger'}/>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md="9">
                                                        <TabContent
                                                            activeTab={verticalActiveTab}
                                                            className="text-muted mt-4"
                                                        >
                                                            {participants?.map((participant, index) => (
                                                                <TabPane key={index} tabId={participant._id}>
                                                                    <Row>
                                                                        <div className="col-md-12 text-center">
                                                                            <img src={participant.profilePic} alt={'avatar'}
                                                                                 className="avatar-md rounded-2 img-thumbnail mb-1 avatar-lg"/>
                                                                            <h5 className="font-size-15 mb-1 text-black">{participant.name || ""}</h5>
                                                                            <p className="text-black mb-2">
                                                                                {participant.role || ""}
                                                                            </p>
                                                                            <p>
                                                                                {participant.description || ""}
                                                                            </p>
                                                                        </div>
                                                                    </Row>
                                                                </TabPane>
                                                            ))}
                                                        </TabContent>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </div>
                                    </TabPane>
                                </TabContent>
                            </div>
                            <div className="actions clearfix">
                                <ul>
                                    <li
                                        className={
                                            activeTab === 1 ? "previous disabled" : "previous"
                                        }
                                    >
                                        <Link
                                            to="#"
                                            onClick={() => {
                                                toggleTab(activeTab - 1)
                                            }}
                                        >
                                            Previous
                                        </Link>
                                    </li>
                                    <li
                                        className={activeTab === 4 ? "next disabled" : "next"}
                                    >
                                        <Link
                                            to="#"
                                            onClick={(e) => {
                                                if (activeTab === 2) {
                                                    handleCreateTable(e)
                                                } else {
                                                    toggleTab(activeTab + 1)
                                                }
                                            }}
                                        >
                                            {activeTab === 2 ? 'Create' : 'Next'}
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
        </React.Fragment>
    )
}