import React, {useState} from "react";
import PropTypes from "prop-types";

import {Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label} from "reactstrap";

//redux
import {useSelector, useDispatch} from "react-redux";
import {createSelector} from "reselect";
import {Link, useNavigate} from "react-router-dom";
import withRouter from "components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import {useFormik} from "formik";


// import images
import profile from "assets/images/profile-img.png";
import logo from "assets/images/logo.svg";
import {loginUser} from "../../services/auth";
import {setCurrentUser} from "../../redux/reducers/auth";

const Login = props => {
    //meta title
    document.title = "Roundtable | Login";

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [formData, setFormData] = React.useState({
        email: "",
        password: "",
    });
    const [isLogin, setIsLogin] = React.useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');

    const handleOnChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        setIsLogin(true);
        if (formData.email === '' || formData.password === '') {
            setError('Please fill all the fields');
            setIsLogin(false);
            return;
        }
        const {data} = await loginUser(formData);
        if (data?.token) {
            localStorage.setItem('userToken', data.token);

            dispatch(setCurrentUser({
                username: data.username,
                email: data.email,
            }))
            setSuccess(true);
            navigate('/dashboard');
        } else {
            setIsLogin(false);
            setError(data?.message);
            setFormData({...formData, password: ''})
        }
    }

    const validation = useFormik({
        // enableReinitialize : use this  flag when initial values needs to be changed
        enableReinitialize: false,

        validationSchema: Yup.object({
            email: Yup.string().required("Please Enter Your Email"),
            password: Yup.string().required("Please Enter Your Password"),
        }),
        onSubmit: (values) => {
            dispatch(loginUser(values, props.router.navigate));
        }
    });


    const signIn = type => {
        dispatch(socialLogin(type, props.router.navigate));
    };

    //for facebook and google authentication
    const socialResponse = type => {
        signIn(type);
    };

    return (
        <React.Fragment>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <div className="bg-primary-subtle">
                                    <Row>
                                        <Col className="col-7">
                                            <div className="text-primary p-4">
                                                <h5 className="text-primary">Welcome Back !</h5>
                                                <p>Sign in to continue to Roundtable.</p>
                                            </div>
                                        </Col>
                                        <Col className="col-5 align-self-end">
                                            <img src={profile} alt="" className="img-fluid"/>
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0">
                                    <div>
                                        <Link to="/" className="logo-light-element">
                                            <div className="avatar-md profile-user-wid mb-4">
                                                <span className="avatar-title rounded-circle bg-light">
                                                    <img src={logo} alt="" className="rounded-circle" height="34"/>
                                                </span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="p-2">
                                        <Form
                                            className="form-horizontal"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                handleOnSubmit(e);
                                                return false;
                                            }}
                                        >
                                            {error ? <Alert color="danger">{error}</Alert> : null}

                                            <div className="mb-3">
                                                <Label className="form-label">Email</Label>
                                                <Input
                                                    name="email"
                                                    className="form-control"
                                                    placeholder="Enter email"
                                                    type="email"
                                                    onChange={(e) => handleOnChange(e)}
                                                    onBlur={validation.handleBlur}
                                                    value={formData.email || ""}
                                                />
                                                {/*{validation.touched.email && validation.errors.email ? (*/}
                                                {/*    <FormFeedback*/}
                                                {/*        type="invalid">{validation.errors.email}</FormFeedback>*/}
                                                {/*) : null}*/}
                                            </div>

                                            <div className="mb-3">
                                                <Label className="form-label">Password</Label>
                                                <Input
                                                    name="password"
                                                    value={formData.password || ""}
                                                    type="password"
                                                    placeholder="Enter Password"
                                                    onChange={(e) => handleOnChange(e)}
                                                    onBlur={validation.handleBlur}
                                                    // invalid={
                                                    //     validation.touched.password && validation.errors.password ? true : false
                                                    // }
                                                />
                                                {/*{validation.touched.password && validation.errors.password ? (*/}
                                                {/*    <FormFeedback*/}
                                                {/*        type="invalid">{validation.errors.password}</FormFeedback>*/}
                                                {/*) : null}*/}
                                            </div>

                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customControlInline"
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customControlInline"
                                                >
                                                    Remember me
                                                </label>
                                            </div>

                                            <div className="mt-3 d-grid">
                                                <button
                                                    className="btn btn-primary btn-block"
                                                    type="submit"
                                                >
                                                    Log In
                                                </button>
                                            </div>

                                            {/*<div className="mt-4 text-center">*/}
                                            {/*  <h5 className="font-size-14 mb-3">Sign in with</h5>*/}

                                            {/*  <ul className="list-inline">*/}

                                            {/*    <li className="list-inline-item">*/}
                                            {/*    <Link*/}
                                            {/*        to="#"*/}
                                            {/*        className="social-list-item bg-danger text-white border-danger"*/}
                                            {/*        onClick={e => {*/}
                                            {/*          e.preventDefault();*/}
                                            {/*          socialResponse("google");*/}
                                            {/*        }}*/}
                                            {/*      >*/}
                                            {/*        <i className="mdi mdi-google" />*/}
                                            {/*      </Link>*/}
                                            {/*    </li>*/}
                                            {/*  </ul>*/}
                                            {/*</div>*/}

                                            <div className="mt-4 text-center">
                                                <Link to="/forgot-password" className="text-muted">
                                                    <i className="mdi mdi-lock me-1"/>
                                                    Forgot your password?
                                                </Link>
                                            </div>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center">
                                <p>
                                    Don&#39;t have an account ?{" "}
                                    <Link to="/register" className="fw-medium text-primary">
                                        {" "}
                                        Signup now{" "}
                                    </Link>{" "}
                                </p>
                                <p>
                                    © {new Date().getFullYear()} Roundtable. Crafted with{" "}
                                    <i className="mdi mdi-heart text-danger"/> by Alpha Sigma Ventures Ltd.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(Login);

Login.propTypes = {
    history: PropTypes.object,
};
