import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  UncontrolledAlert,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

//redux
import { useSelector, useDispatch } from "react-redux"
import withRouter from "components/Common/withRouter"

//Import Breadcrumb
import Breadcrumb from "../../../components/Common/Breadcrumb"

import avatar from "../../../assets/images/users/avatar-blank.jpeg"
import { updateUser } from "../../../services/auth"
import { show } from "../../../redux/reducers/notification"
import { Link } from "react-router-dom"
import { confirmPayment } from "services/subscription"
// actions

const MyPlan = () => {
  //meta title
  document.title = "My Plan | Roundtable"

  const dispatch = useDispatch()

  const currentUser = useSelector(state => state.auth.currentUser)

  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [bio, setBio] = useState("")
  const [idx, setIdx] = useState(1)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    if (currentUser) {
      setName(currentUser.username)
      setEmail(currentUser.email)
      setIdx(currentUser.id)
      console.log(currentUser)
    }
  }, [dispatch])

  //get param from url
  const sessionId = new URLSearchParams(window.location.search).get(
    "session_id"
  )

  const paymentConfirm = async (sessionId) => {
    try {
      const data = {
        sessionId: sessionId,
      }

      const res = await confirmPayment(data)

      if (res.status === "success") {
        if (res.data.redirect) {
          window.location.href = res.data.redirect
        }

        dispatch(
          show({
            title: "Payment",
            body: "Payment successful",
            type: "success",
          })
        )
      } else {
        dispatch(show({ title: "Payment", body: res.message, type: "error" }))
        setError(res.message)
      }
    } catch (error) {
      console.log(error)
      dispatch(
        show({
          title: "Payment",
          body: "Payment Confirmation has an error!",
          type: "error",
        })
      )
    }
  }

  useEffect(() => {
    if (sessionId) {
      paymentConfirm(sessionId)
    }
  }, [sessionId])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Roundtable" breadcrumbItem="My Plan" />

          <Row>
            <Col lg="12">
              {error && error ? (
                <UncontrolledAlert
                  color="danger"
                  className="alert-dismissible fade show"
                >
                  {error}
                </UncontrolledAlert>
              ) : null}
              {success ? (
                <UncontrolledAlert
                  color="success"
                  className="alert-dismissible fade show"
                >
                  {success}
                </UncontrolledAlert>
              ) : null}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img
                        src={currentUser.pic ? currentUser.pic : avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center ms-3">
                      <div className="text-muted">
                        <h5>{currentUser.username}</h5>
                        <p className="mb-1">{currentUser.email}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Token Balance</h4>

          <Card>
            <CardBody>
              <div className="d-flex justify-content-between align-items-center">
                <div className="flex-grow-1">
                  <h5>
                    <b className="text-success">{currentUser.tokens || 0}</b>{" "}
                    tokens
                  </h5>
                  <p className="text-muted">
                    {currentUser.subscription
                      ? currentUser.subscription.plan
                      : "Please purchase a plan "}
                  </p>
                </div>
                <div className="flex-shrink-0 ms-3">
                  <Link
                    to={"/pricing"}
                    className="btn btn-primary btn-md waves-effect waves-light "
                  >
                    Buy More
                  </Link>
                </div>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(MyPlan)
