import React, {useEffect, useRef, useState} from "react"
import {Link, useParams} from "react-router-dom"
import {
    Button,
    Card,
    Col,
    Container,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormGroup,
    Input,
    InputGroup,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    UncontrolledAlert,
    UncontrolledDropdown,
} from "reactstrap"
import classnames from "classnames"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

// simple bar
import SimpleBar from "simplebar-react"
import "simplebar-react/dist/simplebar.min.css"

//redux
import {useSelector, useDispatch} from "react-redux"
import Spinners from "components/Common/Spinner"

import {handleCheckBanWord, handleSearchData} from "components/Common/searchFile"
import user1 from "../../assets/images/users/avatar-blank.jpeg";
import {fetchRoomByFilter, removeRoom, summarizeChat} from "../../services/room";
import {timeFormatter} from "../../common/formater";
import {
    askQuestion,
    clearMessages,
    downloadMessages,
    fetchAllBannedWords,
    fetchAllMessages, starMessage
} from "../../services/message";
import {PulseLoader} from "react-spinners";
import {show} from "../../redux/reducers/notification";
import {getBots, getParticipants} from "../../services/admin";

const Chat = () => {

    document.title = "Roundtable"

    const dispatch = useDispatch();
    const {roomId} = useParams();

    const currentUser = useSelector((state) => state.auth.currentUser);

    const [isLoading, setLoading] = useState(true);
    const [room, setRoom] = useState([]);
    const [messages, setMessages] = useState([]);
    const [messagesData, setMessagesData] = useState();
    const [waiting, setWaiting] = useState(false);
    const [banWords, setBanWords] = useState([]);
    const [bots, setBots] = useState([]);
    const [participants, setParticipants] = useState([]);

    // eslint-disable-next-line no-unused-vars
    const [search_Menu, setsearch_Menu] = useState(false)
    const [settings_Menu, setsettings_Menu] = useState(false)
    const [other_Menu, setother_Menu] = useState(false)
    const [quote, setQuote] = useState(false)
    const [quoteMsg, setQuoteMsg] = useState([])
    const [activeTab, setactiveTab] = useState("1")
    // eslint-disable-next-line no-unused-vars
    const [curMessage, setCurMessage] = useState("")
    const [selectedImage, setSelectedImage] = useState(null)
    const [isDisable, setDisable] = useState(false)
    const [hoverMessage, setHoverMessage] = useState(0)

    //Toggle Chat Box Menus
    const toggleSearch = () => {
        setsearch_Menu(!search_Menu)
    }

    const toggleSettings = () => {
        setsettings_Menu(!settings_Menu)
    }

    const toggleOther = () => {
        setother_Menu(!other_Menu)
    }

    const toggleTab = tab => {
        if (activeTab !== tab) {
            setactiveTab(tab)
        }
    }

    // search data
    const handleSearch = (ele) => {
        handleSearchData({setState: setMessagesData, data: 'messages', item: ele.value})
    }

    const sendQuestion = async () => {
        if (roomId) {
            const filter = await handleCheckBanWord(banWords, curMessage);
            if (filter.length > 0) {
                dispatch(show({title: "Your message contains banned words", body: "", type: "error"}));
            } else {
                if (curMessage !== "" || selectedImage !== null) {
                    setWaiting(true);

                    const newMessages = {
                        createdAt: new Date().getTime(),
                        updatedAt: new Date().getTime(),
                        sender: {
                            name: currentUser?.username,
                        },
                        message: curMessage,
                        summarized: curMessage,
                        type: "ask-user",
                        mentions: quoteMsg,
                    }
                    setMessages([...messages, newMessages]);
                    setDisable(false)
                    setSelectedImage(null)
                    if (participants.length === 0) {
                        dispatch(show({title: "There is no participant in this room", body: "", type: "error"}));
                    } else {
                        console.log(participants);
                        for (let i = 0; i < participants.length; i++) {
                            const newMessage = {
                                roomId: roomId,
                                message: curMessage,
                                summarized: curMessage,
                                images: selectedImage,
                                participantId: participants[i][0]._id,
                                participantName: participants[i][0].name,
                                rule: participants[i][0].functions,
                                order: i,
                            }
                            const res = await askQuestion(newMessage);
                            console.log('resddd',res);
                            if (res.status === 'success') {
                                await fetchMessages(roomId);
                            }
                        }
                        setCurMessage("")
                        setQuoteMsg([])
                        setWaiting(false);
                    }

                }
            }

        } else {
            setDisable(true);
        }
    }


    const onKeyPress = async (e) => {
        const {key, value} = e
        if (key === "Enter") {
            setCurMessage("")
            setDisable(true)
            await sendQuestion()
        }

        if (key === "@") {
            setQuote(true);
        }

        if (key === " ") {
            setQuote(false);
        }

    }

    const handleDeleteMsg = (id) => {
        console.log(id)
    }

    const [copyMsgAlert, setCopyMsgAlert] = useState(false)
    const copyMsg = (ele) => {
        let copyText = ele.closest(".conversation-list").querySelector("p").innerHTML
        navigator.clipboard.writeText(copyText)
        setCopyMsgAlert(true)
        if (copyText) {
            setTimeout(() => {
                setCopyMsgAlert(false)
            }, 1000)

        }
    }

// scroll simple bar
    const scroollRef = useRef(null)
    useEffect(() => {
        if (scroollRef.current) {
            scroollRef.current.getScrollElement().scrollTop = scroollRef.current.getScrollElement().scrollHeight
        }
    }, [curMessage, messages])

    const handleMentionQuote = (message) => {
        if (quoteMsg.length === 0) {
            setQuoteMsg(message);
        } else {
            quoteMsg.includes(message) ? setQuoteMsg([quoteMsg]) : setQuoteMsg([...quoteMsg, message])
        }
        setCurMessage(curMessage + message);
        setQuote(false);
    }
//CRUD
    const handleChatClear = async (roomId) => {
        try {
            const res = await clearMessages({roomId: roomId});
            dispatch(show({title: res.message, body: "", type: res.status}));
            await fetchMessages(roomId);

        } catch (e) {
            dispatch(show({title: "Clear Chat Failed!", body: "", type: 'error'}));
        }

    }

    const handleDownload = async (roomId) => {
        try {
            const res = await downloadMessages({roomId: roomId});
            dispatch(show({title: res.message, body: "", type: res.status}));
            window.location.href = res.data;

        } catch (e) {
            dispatch(show({title: "Download Chat Failed!", body: "", type: 'error'}));
        }

    }

    const handleSummarizeChat = async (roomId) => {
        setLoading(true)
        try {
            const res = await summarizeChat({roomId: roomId});
            dispatch(show({title: res.message, body: "", type: res.status}));
            await fetchMessages(roomId);
            setLoading(false)
        } catch (e) {
            console.log(e)
            dispatch(show({title: "Summarize Chat Failed!", body: "", type: 'error'}));
            setLoading(false);
        }
    }

    const handleRemoveChat = async (roomId) => {
        try {
            const res = await removeRoom({roomId: roomId});
            dispatch(show({title: res.message, body: "", type: res.status}));
            window.location.href = '/dashboard';

        } catch (e) {
            dispatch(show({title: "Remove Table Failed!", body: "", type: 'error'}));
        }
    }

    // handle star and highlight
    const handleMouseOver = (messageId) => {
        setHoverMessage(messageId);
    }

    const handleMouseLeave = () => {
        setHoverMessage(0);
    }

    const handleStar = async (messageId) => {
        const res = await starMessage({messageId: messageId});
        if (res.status === 'success') {
            await fetchMessages(roomId);
        }
    }

    //// end of handle star and highlight
    const fetchRoom = async (roomId) => {
        const res = await fetchRoomByFilter({roomIds: [roomId]});
        if (res) {
            setRoom(res[0]);
        }
        setLoading(false);
    }

    const fetchMessages = async (roomId) => {
        const res = await fetchAllMessages(roomId);
        if (res) {
            console.log(res.data);
            setMessages(res.data);
        }
    }

    const fetchBannedWords = async () => {
        const res = await fetchAllBannedWords();
        if (res.status === 'success') {
            const string = res.data[0]?.words || '';
            const array = string.split(',');
            const newArray = array.map((item) => {
                return item.trim();
            });

            setBanWords(newArray);
        }
    }

    const fetchBots = async () => {
        const res = await getBots();
        if (res) {
            res.data.filter((item) => {
                return room.bots?.includes(item._id);
            });
            setBots(res.data);
        }
    }

    const fetchParticipants = async (roomId) => {
        const res = await getParticipants(roomId);
        if (res) {
            setParticipants(res?.data);
        }
    }

    useEffect(() => {
        if (roomId) {
            fetchRoom(roomId);
            fetchMessages(roomId);
            fetchBannedWords();
            fetchBots()
            fetchParticipants(roomId);
        }
    }, [roomId]);
    useEffect(() => {
        document.body.classList.add("vertical-collpsed");
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs title="Roundtable" breadcrumbItem="Roundtable"/>

                    <Row>
                        <Col lg="12">
                            <div className="d-lg-flex">
                                <div className="chat-leftsidebar me-lg-2">
                                    <div>
                                        <div className="py-4 border-bottom">
                                            <div className="d-flex">
                                                <div className="align-self-center me-3">
                                                    <img
                                                        src={currentUser.pic ? currentUser.pic : user1}
                                                        className="avatar-xs rounded-circle"
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h5 className="font-size-15 mt-0 mb-1">
                                                        {currentUser?.username}
                                                    </h5>
                                                    <p className="text-muted mb-0">
                                                        <i className="mdi mdi-circle text-success align-middle me-2"/>
                                                        Active
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="chat-leftsidebar-nav position-relative">
                                            {/*<Nav pills justified>*/}
                                            {/*    <NavItem>*/}
                                            {/*        <NavLink*/}
                                            {/*            className={classnames({*/}
                                            {/*                active: activeTab === "1"*/}
                                            {/*            })}*/}
                                            {/*            onClick={() => {*/}
                                            {/*                toggleTab("1")*/}
                                            {/*            }}*/}
                                            {/*        >*/}
                                            {/*            <i className="bx bx-chat font-size-20 d-sm-none"/>*/}
                                            {/*            <span className="d-none d-sm-block">Roundtable</span>*/}
                                            {/*        </NavLink>*/}
                                            {/*    </NavItem>*/}
                                            {/*    <NavItem>*/}
                                            {/*        <NavLink*/}
                                            {/*            className={classnames({*/}
                                            {/*                active: activeTab === "3"*/}
                                            {/*            })}*/}
                                            {/*            onClick={() => {*/}
                                            {/*                toggleTab("3")*/}
                                            {/*            }}*/}
                                            {/*        >*/}
                                            {/*            <i className="bx bx-book-content font-size-20 d-sm-none"/>*/}
                                            {/*            <span className="d-none d-sm-block">Contacts</span>*/}
                                            {/*        </NavLink>*/}
                                            {/*    </NavItem>*/}
                                            {/*</Nav>*/}
                                            <TabContent activeTab={activeTab} className="py-4">
                                                <TabPane tabId="1">
                                                    <div>
                                                        {/*<h5 className="font-size-14 mb-3">Recent</h5>*/}
                                                        <ul className="list-unstyled chat-list" id="recent-list">
                                                            {
                                                                isLoading ? <Spinners/> :
                                                                    <SimpleBar style={{maxHeight: "410px"}}>
                                                                        {/*{map(chats, chat => (*/}
                                                                        <li
                                                                            key={1}
                                                                            className={"active mb-2"}
                                                                        >
                                                                            <Link
                                                                                to="#"
                                                                            >
                                                                                <div className="d-flex">
                                                                                    <div
                                                                                        className="align-self-center me-3">
                                                                                        <img src={room.photo || ''}
                                                                                             className="rounded-circle avatar-xs"
                                                                                             alt=""/>
                                                                                    </div>

                                                                                    <div
                                                                                        className="flex-grow-1 overflow-hidden">
                                                                                        <h5 className="text-truncate font-size-13 mb-1">
                                                                                            {room.roomName || ""}
                                                                                        </h5>
                                                                                        <p className="text-truncate mb-0">
                                                                                            {room.context || ""}
                                                                                        </p>
                                                                                    </div>
                                                                                    <div
                                                                                        className="font-size-10 text-primary text-truncate">
                                                                                        {timeFormatter(room.updatedAt)}
                                                                                    </div>
                                                                                </div>
                                                                            </Link>
                                                                        </li>
                                                                        {/*))}*/}

                                                                        {participants && participants.map((participant, index) => (
                                                                            <li
                                                                                key={index}
                                                                                className={"active mb-2"}
                                                                            >
                                                                                <Link
                                                                                    to="#"
                                                                                >
                                                                                    <div className="d-flex">
                                                                                        <div
                                                                                            className="align-self-center me-3">
                                                                                            <img
                                                                                                src={participant[0]?.profilePic || ""}
                                                                                                className="rounded-circle avatar-xs"
                                                                                                alt=""/>
                                                                                        </div>

                                                                                        <div
                                                                                            className="flex-grow-1 overflow-hidden">
                                                                                            <h5 className="text-truncate font-size-12 mb-1">
                                                                                                {participant[0]?.name || ""}
                                                                                            </h5>
                                                                                            <p className="text-truncate font-size-11 mb-0">
                                                                                                {participant[0]?.role || ""}
                                                                                            </p>
                                                                                        </div>
                                                                                        {/*<div*/}
                                                                                        {/*    className="font-size-10 text-primary text-truncate">*/}
                                                                                        {/*    {timeFormatter(room.updatedAt)}*/}
                                                                                        {/*</div>*/}
                                                                                    </div>
                                                                                </Link>
                                                                            </li>
                                                                        ))}
                                                                    </SimpleBar>
                                                            }
                                                        </ul>
                                                    </div>
                                                </TabPane>

                                                <TabPane tabId="3">
                                                    <h5 className="font-size-14 mb-3">Contact</h5>

                                                    <div>
                                                        <SimpleBar style={{height: "410px"}}>
                                                            <div key={"test_"} className={""}>
                                                                <ul className="list-unstyled chat-list">
                                                                    {room.users && room.users.map((user, index) => (
                                                                        <li className={"active"} key={index}>
                                                                            <Link
                                                                                to="#"
                                                                            >
                                                                                <div className="d-flex">
                                                                                    <div
                                                                                        className="align-self-center me-3">
                                                                                        <img
                                                                                            src={user.profilePic ? user.profilePic : user1}
                                                                                            className="rounded-circle avatar-xs"
                                                                                            alt=""/>
                                                                                    </div>

                                                                                    <div
                                                                                        className="flex-grow-1 overflow-hidden">
                                                                                        <h5 className="text-truncate font-size-14 mb-1">
                                                                                            {user.name}
                                                                                        </h5>
                                                                                        <p className="text-truncate mb-0">
                                                                                            {user.email}
                                                                                        </p>
                                                                                    </div>
                                                                                    <div
                                                                                        className="font-size-10 text-primary text-truncate">
                                                                                        {timeFormatter(user.updatedAt)}
                                                                                    </div>
                                                                                </div>
                                                                            </Link>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </SimpleBar>
                                                    </div>
                                                </TabPane>
                                            </TabContent>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-100 user-chat">
                                    <Card>
                                        <div className="p-4 border-bottom ">
                                            <Row>
                                                <Col md="4" xs="9">
                                                    <h5 className="font-size-15 mb-1 align-items-center">
                                                        {room.roomName} (<b className="text-success">{currentUser.tokens || 0}</b> Tokens)
                                                    </h5>
                                                </Col>
                                                <Col md="8" xs="3">
                                                    <ul className="list-inline user-chat-nav text-end mb-0">
                                                        <li className="list-inline-item d-none d-sm-inline-block">
                                                            <Dropdown
                                                                className="me-1"
                                                                isOpen={search_Menu}
                                                                toggle={toggleSearch}
                                                            >
                                                                <DropdownToggle className="btn nav-btn" tag="a">
                                                                    <i className="bx bx-search-alt-2"/>
                                                                </DropdownToggle>
                                                                <DropdownMenu
                                                                    className="dropdown-menu-md"
                                                                >
                                                                    <Form className="p-3">
                                                                        <FormGroup className="m-0">
                                                                            <InputGroup>
                                                                                <Input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    placeholder="Search ..."
                                                                                    aria-label="Recipient's username"
                                                                                    onChange={(e) => handleSearch(e.target)}
                                                                                />
                                                                                {/* <InputGroupAddon addonType="append"> */}
                                                                                <Button color="primary" type="submit">
                                                                                    <i className="mdi mdi-magnify"/>
                                                                                </Button>
                                                                                {/* </InputGroupAddon> */}
                                                                            </InputGroup>
                                                                        </FormGroup>
                                                                    </Form>
                                                                </DropdownMenu>
                                                            </Dropdown>
                                                        </li>
                                                        <li className="list-inline-item d-none d-sm-inline-block">
                                                            <Dropdown
                                                                isOpen={settings_Menu}
                                                                toggle={toggleSettings}
                                                                className="me-1"
                                                            >
                                                                <DropdownToggle className="btn nav-btn" tag="a">
                                                                    <i className="bx bx-cog"/>
                                                                </DropdownToggle>
                                                                <DropdownMenu>
                                                                    <DropdownItem
                                                                        onClick={() => handleDownload(roomId)}>Download</DropdownItem>
                                                                    <DropdownItem
                                                                        onClick={() => handleSummarizeChat(roomId)}>Summarize</DropdownItem>
                                                                    <DropdownItem
                                                                        onClick={() => handleChatClear(roomId)}>Clear
                                                                        chat</DropdownItem>
                                                                    <DropdownItem
                                                                        onClick={() => handleRemoveChat(roomId)}>Delete</DropdownItem>
                                                                </DropdownMenu>
                                                            </Dropdown>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <Dropdown
                                                                isOpen={other_Menu}
                                                                toggle={toggleOther}
                                                            >
                                                                <DropdownToggle className="btn nav-btn" tag="a">
                                                                    <i className="bx bx-dots-horizontal-rounded"/>
                                                                </DropdownToggle>
                                                                <DropdownMenu className="dropdown-menu-end">
                                                                    <DropdownItem href="#">Action</DropdownItem>
                                                                    <DropdownItem href="#">
                                                                        Another Action
                                                                    </DropdownItem>
                                                                    <DropdownItem href="#">
                                                                        Something else
                                                                    </DropdownItem>
                                                                </DropdownMenu>
                                                            </Dropdown>
                                                        </li>
                                                    </ul>
                                                </Col>
                                            </Row>
                                        </div>

                                        <div>

                                            <div className="chat-conversation p-3">

                                                <SimpleBar ref={scroollRef} style={{height: "486px"}}>
                                                    {isLoading ? <Spinners/> :
                                                        <ul className="list-unstyled mb-0">
                                                            {
                                                                messages && messages.map((userMsg, index) => {
                                                                    return (
                                                                        (userMsg.type !== 'duplicated' && userMsg.summarized) && (
                                                                            <li key={index}
                                                                                className={userMsg.type === 'ask-user' ? 'right' : 'left'}
                                                                            >
                                                                                <div className="conversation-list">
                                                                                    <UncontrolledDropdown>
                                                                                        <DropdownToggle
                                                                                            href="#!"
                                                                                            tag="a"
                                                                                            className="dropdown-toggle"
                                                                                        >
                                                                                            <i className="bx bx-dots-vertical-rounded"/>
                                                                                        </DropdownToggle>
                                                                                        <DropdownMenu>
                                                                                            <DropdownItem
                                                                                                onClick={(e) => copyMsg(e.target)}
                                                                                                href="#">
                                                                                                Copy
                                                                                            </DropdownItem>
                                                                                            <DropdownItem
                                                                                                onClick={(e) => handleDeleteMsg(userMsg._id)}
                                                                                                href="#">
                                                                                                Delete
                                                                                            </DropdownItem>

                                                                                        </DropdownMenu>
                                                                                    </UncontrolledDropdown>
                                                                                    <div
                                                                                        onMouseOver={() => handleMouseOver(userMsg._id)}
                                                                                        onMouseLeave={() => handleMouseLeave(userMsg._id)}
                                                                                        className={`ctext-wrap ${userMsg.type === 'ask-user' ? '' : 'bg-success-subtle'}`}>
                                                                                        <div
                                                                                            className="conversation-name">
                                                                                            {userMsg.type === 'ask-user' && userMsg.summarized ? userMsg?.sender?.name : userMsg.type}
                                                                                        </div>
                                                                                        <div className={'left'}>{userMsg.summarized.split(/[\r\n]+/).map((line, index) => <div key={index}>{line}</div>)}</div>
                                                                                        <div
                                                                                            className={'d-flex align-items-center justify-content-between '}
                                                                                            style={{height: '24px'}}>
                                                                                        <span
                                                                                            className="d-flex align-items-center chat-time mb-0 text-danger">
                                                                                            <i className="bx bx-time-five align-middle me-1"/>
                                                                                            {timeFormatter(userMsg.createdAt)}
                                                                                        </span>
                                                                                            <a className={`${hoverMessage === userMsg._id || userMsg.star ? 'd-block' : 'd-none'} mb-0`}
                                                                                               onClick={() => handleStar(userMsg._id)}>
                                                                                                <i className={`bx ${userMsg.star ? 'bxs-star' : 'bx-star'} text-warning align-middle font-size-16`}/>
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        )
                                                                    )
                                                                })}
                                                        </ul>
                                                    }
                                                </SimpleBar>

                                                <PulseLoader color={'#3a3a3a'} size={6} loading={waiting}
                                                             className={'d-inline-block bg-secondary-subtle btn-rounded px-1'}/>
                                            </div>
                                            {selectedImage &&
                                            <div className="replymessage-block mb-0 d-flex align-items-start">
                                                <div className="flex-grow-1">
                                                    <img src={selectedImage} alt="select img"
                                                         style={{width: "150px", height: "auto"}}/>
                                                </div>
                                                <div className="flex-shrink-0">
                                                    <button type="button" id="close_toggle"
                                                            className="btn btn-sm btn-link mt-n2 me-n3 fs-18"
                                                            onClick={() => setSelectedImage(null)}>
                                                        <i className="bx bx-x align-middle"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            }

                                            {copyMsgAlert &&
                                            <UncontrolledAlert color="warning" dismissible role="alert">
                                                Message copied
                                            </UncontrolledAlert>
                                            }

                                            {quote &&
                                            <div
                                                className={`quote-section rounded-2 bg-white border border-1 p-2 ms-2`}>
                                                {bots.map((bot, index) => (
                                                    <Link key={index} onClick={() => handleMentionQuote(bot.name)}
                                                          className={`text-decoration-none w-100 p-1 `}>@{bot.name}</Link>
                                                ))}
                                            </div>
                                            }

                                            <div className="p-3 chat-input-section">
                                                <Row>
                                                    <Col>
                                                        <div className="position-relative">
                                                            <input
                                                                type="text"
                                                                value={curMessage}
                                                                onKeyPress={onKeyPress}
                                                                onChange={e => {
                                                                    setCurMessage(e.target.value)
                                                                    setDisable(true)
                                                                }}
                                                                className="form-control chat-input"
                                                                placeholder="Enter Message..."
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col className="col-auto">
                                                        <Button
                                                            type="button"
                                                            color="primary"
                                                            disabled={!isDisable}
                                                            onClick={() => sendQuestion()}
                                                            className="btn btn-primary btn-rounded chat-send w-md "
                                                        >
                                                              <span className="d-none d-sm-inline-block me-2">
                                                                Send
                                                              </span>{" "}
                                                            <i className="mdi mdi-send"/>
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Chat
