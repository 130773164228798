import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Collapse,
  Form,
  Input,
  Label,
  Row,
  FormText,
} from "reactstrap"
import { deleteCoin, getCoins, updateCoin } from "../../../services/admin"
import { show } from "../../../redux/reducers/notification"
import { useDispatch } from "react-redux"

export const CoinConfig = () => {
  const dispatch = useDispatch()

  const [coinFormData, setCoinFormData] = useState({
    name: "",
    endpoint: "",
    key: "",
  })
  const [isOpen, setIsOpen] = useState(false)
  const [isCoinOpen, setIsCoinOpen] = useState(0)
  const [coins, setCoins] = useState([])

  const toggle = () => setIsOpen(!isOpen)

  const handleFormChange = e => {
    setCoinFormData({
      ...coinFormData,
      [e.target.name]: e.target.value,
    })
  }

  const handleCoinConfig = async () => {
    try {
      const res = await updateCoin(coinFormData)
      console.log("res", res)
      dispatch(show({ title: res.message, body: "", type: res.status }))
      if (res.status === "success") {
        await fetchConfigs()
        toggle()
      }
    } catch (e) {
      console.log(e)
      dispatch(show({ title: "Something went wrong", body: "", type: "error" }))
    }
  }

  const removeCoin = async botId => {
    try {
      const res = await deleteCoin(botId)
      console.log("res", res)
      if (res.status === "success") {
        await fetchConfigs()
        dispatch(show({ title: res.message, body: "", type: res.status }))
      }
    } catch (e) {
      console.log(e.response.data)
      dispatch(
        show({ title: e.response.data.message, body: "", type: "error" })
      )
    }
  }

  const fetchConfigs = async () => {
    const res = await getCoins()
    if (res) {
      console.log(res)
      setCoins(res.data)
    }
  }

  useEffect(() => {
    fetchConfigs()
  }, [])

  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <h4 className="card-title mb-1 d-flex justify-content-between align-items-center">
                Coin Config (rate = token/coin)
                <a
                  href="#"
                  onClick={toggle}
                  className="btn btn-outline-success"
                >
                  <i className="bx bx-plus-circle align-middle" /> Add New
                </a>
              </h4>
              <div className="wizard clearfix">
                <div className={`${isOpen && "border"} p-2 `}>
                  <Collapse isOpen={isOpen} id="betConfig-collapse">
                    <Form
                      onSubmit={e => {
                        e.preventDefault()
                        handleCoinConfig()
                      }}
                    >
                      <Row>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="bot-name">Provider Name</Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="provider-name"
                              name="name"
                              placeholder="Enter Provider Name"
                              onChange={e => {
                                handleFormChange(e)
                              }}
                            />
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="api-key">Rate</Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="coin-key"
                              name="rate"
                              placeholder="Enter Your Rate"
                              onChange={e => {
                                handleFormChange(e)
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <div className="">
                            <Button type={"submit"} color="primary">
                              Create
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </Collapse>
                </div>
              </div>
              <Row>
                <Col lg="12">
                  <ul className="list-unstyled mb-0">
                    {coins &&
                      coins.map((coin, index) => (
                        <li
                          key={index}
                          className="d-flex justify-content-between align-items-center border rounded-pill px-4 py-1 mt-2"
                        >
                          <div>
                            <h5 className="mb-0">{coin.name}</h5>
                          </div>
                          <div>
                            <p className="mb-0">Rate: <b className="text-success">{coin.rate}</b></p>
                          </div>
                          <div className={""}>
                            <a
                              className="btn btn-outline-danger btn-sm mx-1"
                              onClick={() => removeCoin(coin._id)}
                            >
                              <i className="bx bx-trash-alt mr-1" />
                            </a>
                          </div>
                        </li>
                      ))}
                  </ul>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}
