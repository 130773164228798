import axios from "axios";

const API = (token) =>
    axios.create({
        baseURL: process.env.REACT_APP_SERVER_URL,
        headers: {Authorization: token},
    });

export const createRoom = async (body) => {
    try {
        const token = localStorage.getItem("userToken");

        const {data} = await API(token).post("/api/room", body);
        console.log(data.message);
        return data;
    } catch (error) {
        console.log(error.response.data);
        return error.response.data;
    }
}

export const fetchAllRooms = async () => {
    try {
        const token = localStorage.getItem("userToken");
        const {data} = await API(token).get("/api/room");
        return data;
    } catch (error) {
        console.log(error.response.data);
        return error.response.data;
    }
}

export const fetchRoomByFilter = async (body) => {
    console.log(body);
    try {
        const token = localStorage.getItem("userToken");
        const {data} = await API(token).post("/api/room/filter", body);
        return data;
    } catch (error) {
        console.log("error in fetch room by filter api");
        return error.response.data;
    }
}

export const removeRoom = async (body) => {
    try {
        const token = localStorage.getItem("userToken");
        const {data} = await API(token).patch("/api/room/remove", body);
        return data;
    } catch (error) {
        console.log(error.response.data);
        return error.response.data;
    }
}

export const summarizeChat = async (body) => {
    try {
        const token = localStorage.getItem("userToken");
        const {data} = await API(token).post("/api/room/summarize", body);
        return data;
    } catch (error) {
        console.log(error.response.data);
        return error.response.data;
    }
}