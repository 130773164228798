import axios from "axios";

const API = (token) =>
    axios.create({
        baseURL: process.env.REACT_APP_SERVER_URL,
        headers: {Authorization: token},
    });

export const askQuestion = async (body) => {
    try {
        const token = localStorage.getItem("userToken");

        console.log('message ====>',body);
        // const {data} = await API(token).post("/api/message", body);
        const {data} = await API(token).post("/api/message/participants", body);
        return data;
    } catch (error) {
        console.log(error.response.data);
        return error.response.data;
    }
}

export const fetchAllMessages = async (roomId) => {
    try {
        const token = localStorage.getItem("userToken");
        const {data} = await API(token).get(`/api/message/${roomId}`);
        return data;
    } catch (error) {
        console.log(error.response.data);
        return error.response.data;
    }
}

export const clearMessages = async (body) => {
    try {
        const token = localStorage.getItem("userToken");
        const {data} = await API(token).patch(`/api/message/remove`, body);
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const fetchAllBannedWords = async () => {
    try {
        const token = localStorage.getItem("userToken");
        const {data} = await API(token).get(`/api/admin/banned-words`);
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const downloadMessages = async (body) => {
    try {
        const token = localStorage.getItem("userToken");
        const {data} = await API(token).post(`/api/message/download`, body);
        return data;
    } catch (e) {
        return e.response.data;
    }
}

export const starMessage = async (body) => {
    try {
        const token = localStorage.getItem("userToken");
        const {data} = await API(token).post(`/api/message/star`, body);
        return data;
    } catch (e) {
        return e.response.data;
    }
}