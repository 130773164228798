import React from "react"
import { Container, Row, Col } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//Import Pricing Cards
import CardPricing from "./card-pricing"

const PagesPricing = () => {

    //meta title
    document.title = "Pricing | Roundtable";

    const pricings = [
        {
            id: 1,
            title: "Small",
            plan: "small",
            description: "This is small plan",
            icon: "bx-walk",
            price: "1,000",
            duration: "Tokens",
            link: "",
            features: [
                { title: "Free Live Support" },
                { title: "Unlimited User" },
                { title: "No Time Tracking" },
                { title: "Free Setup" },
            ],
        },
        {
            id: 2,
            title: "Medium",
            plan: "medium",
            description: "This is medium plan",
            icon: "bx-cycling",
            price: "5,000",
            duration: "Tokens",
            link: "",
            features: [
                { title: "Free Live Support" },
                { title: "Unlimited User" },
                { title: "No Time Tracking" },
                { title: "Free Setup" },
            ],
        },
        {
            id: 3,
            title: "Large",
            plan: "large",
            description: "This is high plan",
            icon: "bx-car",
            price: "10,000",
            duration: "Tokens",
            link: "",
            features: [
                { title: "Free Live Support" },
                { title: "Unlimited User" },
                { title: "No Time Tracking" },
                { title: "Free Setup" },
            ],
        },
    ]
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Roundtable" breadcrumbItem="Pricing" />

                    <Row className="justify-content-center">
                        <Col lg={6}>
                            <div className="text-center mb-5">
                                <h4>Choose your subscription plan</h4>
                                <p className="text-muted">
                                    You will get more tokens with increase of your subscription plan.
                                </p>
                            </div>
                        </Col>
                    </Row>

                    <Row className="justify-content-center">
                        {pricings.map((pricing, key) => (
                            <CardPricing pricing={pricing} key={"_pricing_" + key} />
                        ))}
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default PagesPricing
