import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    loginStatus: false,
    currentUser: {
        username:'',
        email:'',
        id:'',
        bio: '',
        pic: '',
        subscription: {},
        tokens: 0
    }
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setLoginStatus(state, action) {
            state.loginStatus = action.payload;
        },
        setCurrentUser(state, action) {
            state.currentUser = action.payload;
        }
    }
});

export const {
    setLoginStatus,
    setCurrentUser
} = authSlice.actions;

export default authSlice.reducer;