import axios from "axios";

const API = (token) => axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    headers: { Authorization: token },
});

let url = process.env.REACT_APP_SERVER_URL;

export const loginUser = async (body) => {
    try {
        return await axios.post(`${url}/auth/login`, body);
    } catch (error) {
        console.log("error in login user api");
    }
}

export const googleAuth = async (body) => {
    try {
        return await axios.post(`${url}/api/google`, body);
    } catch (error) {
        console.log(error);
    }
}

export const registerUser = async (body) => {
    try {
        return await axios.post(`${url}/auth/register`, body);
    } catch (error) {
        console.log("error in register api", error);
    }
}

export const validUser = async () => {
    try {
        const token = localStorage.getItem("userToken");

        const { data } = await API(token).get(`/auth/valid`, {
            headers: { Authorization: token },
        });
        return data;
    } catch (error) {
        console.log("error in valid user api");
    }
}

export const validAdmin = async () => {
    try {
        const token = localStorage.getItem("userToken");

        const { data } = await API(token).get(`/auth/valid-admin`, {
            headers: { Authorization: token },
        });
        return data;
    } catch (error) {
        console.log("error in valid admin api");
    }
}

export const searchUsers = async (id) => {
    try {
        const token = localStorage.getItem("userToken");

        return await API(token).get(`/api/user?search=${id}`);
    } catch (error) {
        console.log("error in search users api");
    }
}

export const updateUser = async (id, body) => {
    try {
        const token = localStorage.getItem("userToken");

        const { data } = await API(token).patch(`/api/users/update/${id}`, body);
        console.log('data', data);
        return data;
    } catch (error) {
        console.log("error in update user api");
    }
}

export const resetPassword = async (body) => {
    try {
        return await axios.post(`${url}/auth/reset`, body);
    } catch (error) {
        console.log("error in reset password api");
    }
}