import React, {useEffect, useState} from "react";
import {Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import {useFormik} from "formik";

//redux
import {useSelector, useDispatch} from "react-redux";

import {Link, useNavigate} from "react-router-dom";

// import images
import profileImg from "../../assets/images/profile-img.png";
import logoImg from "../../assets/images/logo.svg";
import {registerUser} from "../../services/auth";

const Register = () => {
    //meta title
    document.title = "Roundtable | Register";

    const navigate = useNavigate();

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: '',
            username: '',
            password: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please Enter Your Email"),
            username: Yup.string().required("Please Enter Your Username"),
            password: Yup.string().required("Please Enter Your Password"),
        }),
    });

    const [formData, setFormData] = useState({
        email: '',
        username: '',
        password: '',
    });
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');

    const handleOnChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    }

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            if (formData.email === '' || formData.username === '' || formData.password === '') {
                setError('Please fill all the fields');
                setIsLoading(false);
                return false;
            }
            const {data} = await registerUser(formData);
            console.log('data', data)
            if (data?.token) {
                setSuccess(true);
                localStorage.setItem("userToken", data.token);
                setIsLoading(false);
                navigate("/login");
            } else {
                setIsLoading(false);
                setError('Invalid Credentials');
            }
        } catch (err) {
            setIsLoading(false);
            setError('Already Exists');
            setFormData({...formData, password: ''});
        }
    }

    return (
        <React.Fragment>
            <div className="home-btn d-none d-sm-block">
                <Link to="/" className="text-dark">
                    <i className="bx bx-home h2"/>
                </Link>
            </div>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <div className="bg-primary-subtle">
                                    <Row>
                                        <Col className="col-7">
                                            <div className="text-primary p-4">
                                                <h5 className="text-primary">Free Register</h5>
                                                <p>Get your free Roundtable account now.</p>
                                            </div>
                                        </Col>
                                        <Col className="col-5 align-self-end">
                                            <img src={profileImg} alt="" className="img-fluid"/>
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0">
                                    <div>
                                        <Link to="/">
                                            <div className="avatar-md profile-user-wid mb-4">
                                                <span className="avatar-title rounded-circle bg-light">
                                                  <img src={logoImg} alt="" className="rounded-circle" height="34"/>
                                                </span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="p-2">
                                        <Form
                                            className="form-horizontal"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                handleOnSubmit(e);
                                                return false;
                                            }}
                                        >
                                            {success ? (
                                                <Alert color="success">
                                                    Register User Successfully
                                                </Alert>
                                            ) : null}

                                            {error ? (
                                                <Alert color="danger">{error}</Alert>
                                            ) : null}

                                            <div className="mb-3">
                                                <Label className="form-label">Email</Label>
                                                <Input
                                                    id="email"
                                                    name="email"
                                                    className="form-control"
                                                    placeholder="Enter email"
                                                    type="email"
                                                    onChange={(e) => {
                                                        validation.handleChange;
                                                        handleOnChange(e)
                                                    }}
                                                    onBlur={validation.handleBlur}
                                                    value={formData.email || ""}
                                                    // invalid={
                                                    //     validation.touched.email && validation.errors.email ? true : false
                                                    // }
                                                />
                                                {/*{validation.touched.email && validation.errors.email ? (*/}
                                                {/*    <FormFeedback*/}
                                                {/*        type="invalid">{validation.errors.email}</FormFeedback>*/}
                                                {/*) : null}*/}
                                            </div>

                                            <div className="mb-3">
                                                <Label className="form-label">Username</Label>
                                                <Input
                                                    name="username"
                                                    type="text"
                                                    placeholder="Enter username"
                                                    onChange={(e) => {
                                                        validation.handleChange;
                                                        handleOnChange(e)
                                                    }}
                                                    onBlur={validation.handleBlur}
                                                    value={formData.username || ""}
                                                    // invalid={
                                                    //     validation.touched.username && validation.errors.username ? true : false
                                                    // }
                                                />
                                                {/*{validation.touched.username && validation.errors.username ? (*/}
                                                {/*    <FormFeedback*/}
                                                {/*        type="invalid">{validation.errors.username}</FormFeedback>*/}
                                                {/*) : null}*/}
                                            </div>
                                            <div className="mb-3">
                                                <Label className="form-label">Password</Label>
                                                <Input
                                                    name="password"
                                                    type="password"
                                                    placeholder="Enter Password"
                                                    onChange={(e) => {
                                                        validation.handleChange;
                                                        handleOnChange(e)
                                                    }}
                                                    onBlur={validation.handleBlur}
                                                    value={formData.password || ""}
                                                    // invalid={
                                                    //     validation.touched.password && validation.errors.password ? true : false
                                                    // }
                                                />
                                                {/*{validation.touched.password && validation.errors.password ? (*/}
                                                {/*    <FormFeedback*/}
                                                {/*        type="invalid">{validation.errors.password}</FormFeedback>*/}
                                                {/*) : null}*/}
                                            </div>

                                            <div className="mt-4">
                                                <button className="btn btn-primary btn-block " type="submit">
                                                    Register
                                                </button>
                                            </div>

                                            <div className="mt-4 text-center">
                                                <p className="mb-0">
                                                    By registering you agree to the Skote{" "}
                                                    <Link to="#" className="text-primary">
                                                        Terms of Use
                                                    </Link>
                                                </p>
                                            </div>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center">
                                <p>
                                    Already have an account ?{" "}
                                    <Link to="/login" className="font-weight-medium text-primary">
                                        {" "}
                                        Login
                                    </Link>{" "}
                                </p>
                                <p>
                                    © {new Date().getFullYear()} Roundtable. Crafted with{" "}
                                    <i className="mdi mdi-heart text-danger"/> by Alpha Sigma Ventures Ltd.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Register;
