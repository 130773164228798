import axios from "axios"

const API = token =>
  axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    headers: { Authorization: token },
  })

// ban word config crud
export const updateBannedWords = async body => {
  try {
    const token = localStorage.getItem("userToken")
    const { data } = await API(token).post(`/api/admin/banned-words`, body)
    return data
  } catch (e) {
    return e.response.data
  }
}

// bot config crud
export const updateBot = async body => {
  try {
    const token = localStorage.getItem("userToken")
    const { data } = await API(token).post(`/api/admin/add-bot`, body)
    return data
  } catch (e) {
    return e.response.data
  }
}

export const getBots = async () => {
  try {
    const token = localStorage.getItem("userToken")
    const { data } = await API(token).get(`/api/admin/get-bots`)
    return data
  } catch (e) {
    return e.response.data
  }
}

export const deleteBot = async id => {
  try {
    const token = localStorage.getItem("userToken")
    const { data } = await API(token).patch(`/api/admin/delete-bot/${id}`)
    return data
  } catch (e) {
    return e.response.data
  }
}

//coin config crud
export const updateCoin = async body => {
  try {
    const token = localStorage.getItem("userToken")
    const { data } = await API(token).post(`/api/admin/add-coin`, body)
    return data
  } catch (e) {
    return e.response.data
  }
}

export const getCoins = async () => {
  try {
    const token = localStorage.getItem("userToken")
    const { data } = await API(token).get(`/api/admin/get-coins`)
    return data
  } catch (e) {
    return e.response.data
  }
}

export const deleteCoin = async id => {
  try {
    const token = localStorage.getItem("userToken")
    const { data } = await API(token).patch(`/api/admin/delete-coin/${id}`)
    return data
  } catch (e) {
    return e.response.data
  }
}

// user management crud
export const updateUser = async body => {
  try {
    const token = localStorage.getItem("userToken")
    const { data } = await API(token).post(`/api/admin/users`, body)
    return data
  } catch (e) {
    return e.response.data
  }
}

export const getAllUsers = async () => {
  try {
    const token = localStorage.getItem("userToken")
    const { data } = await API(token).get(`/api/admin/users`)
    return data
  } catch (e) {
    return e.response.data
  }
}

export const deleteUser = async id => {
  try {
    const token = localStorage.getItem("userToken")
    const { data } = await API(token).patch(`/api/admin/users/${id}`)
    return data
  } catch (e) {
    return e.response.data
  }
}
// participants crud
export const addParticipant = async body => {
  try {
    const token = localStorage.getItem("userToken")
    const { data } = await API(token).post(`/api/admin/participants`, body)
    return data
  } catch (e) {
    return e.response.data
  }
}

export const updateParticipant = async (id, body) => {
  try {
    const token = localStorage.getItem("userToken")
    const { data } = await API(token).post(
      `/api/admin/participants/${id}`,
      body
    )
    return data
  } catch (e) {
    console.log(e.response.data)
    return e.response.data
  }
}

export const getParticipants = async roomId => {
  try {
    const token = localStorage.getItem("userToken")
    const { data } = await API(token).get(`/api/admin/participants/${roomId}`)
    console.log(data)
    return data
  } catch (e) {
    return e.response.data
  }
}

export const getAllParticipants = async () => {
  try {
    const token = localStorage.getItem("userToken")
    const { data } = await API(token).get(`/api/admin/participants`)
    return data
  } catch (e) {
    return e.response.data
  }
}

export const deleteParticipant = async id => {
  try {
    const token = localStorage.getItem("userToken")
    const { data } = await API(token).patch(`/api/admin/participants/${id}`)
    return data
  } catch (e) {
    return e.response.data
  }
}
