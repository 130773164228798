import React, {useEffect, useMemo, useState} from "react";
import PropTypes from 'prop-types';

import DataTable from 'react-data-table-component';

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import {useDispatch} from "react-redux";
import {Button, Input, InputGroup} from "reactstrap";
import {deleteUser, getAllUsers} from "../../../services/admin";
import {show} from "../../../redux/reducers/notification";

function Users() {
    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Created At',
            selector: row => row.created_at,
            sortable: true,
        },
        {
            name: 'Membership',
            selector: row => row.membership,
            sortable: true,
        },
        {
            name: 'Role',
            selector: row => row.role,
            sortable: true,
        },
        {
            cell: (row) => <div className={'d-flex flex-row justify-content-around'}><button className="btn btn-primary" onClick={() => editUser(row)}><i className={'bx bx-edit'} /></button><button className="btn btn-danger" onClick={() => removeUser(row)}><i className={'bx bx-trash'} /></button> </div>,
            selector: row => row.role,
            sortable: true,
        },
    ];

    //  Internally, customStyles will deep merges your customStyles with the default styling.
    const customStyles = {
        rows: {
            style: {
                minHeight: '42px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                backgroundColor: '#e4e4e4',
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
    };

    //meta title
    document.title = "RoundTable | Admin/Users";

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(true);
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [users, setUsers] = useState([]);

    const filteredItems = users.filter(
        item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
    );

    const filterUser = (e) => {
        setFilterText(e.value);
    }

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <InputGroup className={'col-sm-4'}>
                <Input
                    type="text"
                    className="form-control"
                    placeholder="Search ..."
                    aria-label="Recipient's username"
                    onChange={(e) => filterUser(e.target)}
                />
                {/* <InputGroupAddon addonType="append"> */}
                <Button color="primary" type="submit">
                    <i className="mdi mdi-magnify"/>
                </Button>
                {/* </InputGroupAddon> */}
            </InputGroup>
        );
    }, [filterText, resetPaginationToggle]);

    const fetchUsers = async () => {
        const res = await getAllUsers();
        console.log('res', res);
        let users = [];
        if (res.status === 'success') {
            res.data.map((user) => {
                users.push({
                    name: user.name,
                    email: user.email,
                    created_at: user.created_at,
                    membership: 1, //user.membership,
                    role: user.isAdmin? 'Admin' : 'User',
                    id: user._id
                })
            });
            setUsers(users);
        }
        setIsLoading(false);
    }

    const removeUser = async (user) => {
        console.log('remove user', user);
        const res = await deleteUser(user.id);
        console.log('res', res);
        if (res.status === 'success') {
            dispatch(show({title: 'User removed!', body: "", type: 'success'}));
            await fetchUsers();
        } else {
            dispatch(show({title: 'Something went wrong', body: "", type: 'error'}));
        }
    }

    const editUser = async (user) => {
        console.log(user)
    }

    useEffect(() => {
        console.log('fetch user')
        fetchUsers();
    }, []);

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title="Admin" breadcrumbItem="Users"/>
                <DataTable
                    columns={columns}
                    data={users}
                    progressPending={isLoading}
                    highlightOnHover={true}
                    pagination

                    customStyles={customStyles}
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    persistTableHead={true}
                />
            </div>
        </div>
    );
}

Users.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};


export default Users;