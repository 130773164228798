import React, {useEffect, useState} from "react"
import {Button, Card, CardBody, Col, Container, Form, Input, Label, Row} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import {withTranslation} from "react-i18next"

//redux
import {useSelector, useDispatch} from "react-redux"
import {Link} from "react-router-dom"
import {show} from "../../../redux/reducers/notification";
import Spinner from "../../../components/Common/Spinner";
import {updateBannedWords} from "../../../services/admin";
import {BannedWords} from "./BannedWords";
import {BotConfig} from "./BotConfig";
import {Participants} from "./Participants";
import { CoinConfig } from "./CoinConfig";


export const Setting = () => {
    //meta title
    document.title = "Roundtable | Admin/Setting";

    const [isLoading, setIsLoading] = useState()

    useEffect(() => {
        setIsLoading(false);
    }, [])


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Admin" breadcrumbItem="Setting"/>
                    <BannedWords />
                    <BotConfig />
                    <Participants />
                    <CoinConfig />
                </Container>
            </div>
        </React.Fragment>
    )
}