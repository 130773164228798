import React, {useState, useEffect} from "react";
import {
    Container,
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Button,
    Label,
    Input,
    FormFeedback,
    Form,
    UncontrolledAlert,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import {useFormik} from "formik";

//redux
import {useSelector, useDispatch} from "react-redux";
import withRouter from "components/Common/withRouter";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/avatar-blank.jpeg";
import {updateUser} from "../../services/auth";
import {show} from "../../redux/reducers/notification";
// actions

const UserProfile = () => {

    //meta title
    document.title = "Profile | Roundtable";

    const dispatch = useDispatch();

    const currentUser = useSelector(state => state.auth.currentUser);

    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [bio, setBio] = useState("");
    const [idx, setIdx] = useState(1);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const handleUpdateProfile = async (e) => {
        e.preventDefault();
        const body = {
            name: name,
            bio: bio,
        }
        const data = await updateUser(currentUser.id, body);
        if (data.message === "success" ) {
            setSuccess(data.message);
            setName(body.name);
            setIdx(currentUser.id);
            setBio(body.bio);
        } else {
            setError(data.message);
        }
    }

    useEffect(() => {
        if (currentUser) {
            setName(currentUser.username);
            setEmail(currentUser.email);
            setIdx(currentUser.id);
            console.log(currentUser);
        }
    }, [dispatch]);

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            username: name || '',
            idx: idx || '',
        },
        validationSchema: Yup.object({
            username: Yup.string().required("Please Enter Your UserName"),
        }),
    });


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="Roundtable" breadcrumbItem="Profile"/>

                    <Row>
                        <Col lg="12">
                            {error && error ? <UncontrolledAlert color="danger" className="alert-dismissible fade show">{error}</UncontrolledAlert> : null}
                            {success ? <UncontrolledAlert color="success" className="alert-dismissible fade show">{success}</UncontrolledAlert> : null}

                            <Card>
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="ms-3">
                                            <img
                                                src={currentUser.pic ? currentUser.pic : avatar}
                                                alt=""
                                                className="avatar-md rounded-circle img-thumbnail"
                                            />
                                        </div>
                                        <div className="flex-grow-1 align-self-center ms-3">
                                            <div className="text-muted">
                                                <h5>{currentUser.username}</h5>
                                                <p className="mb-1">{currentUser.email}</p>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <h4 className="card-title mb-4">Change User Name</h4>

                    <Card>
                        <CardBody>
                            <Form
                                className="form-horizontal"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleUpdateProfile(e);
                                    return false;
                                }}
                            >
                                <div className="form-group">
                                    <Label className="form-label">User Name</Label>
                                    <Input
                                        name="username"
                                        // value={name}
                                        className="form-control"
                                        placeholder="Enter User Name"
                                        type="text"
                                        onChange={(e) => setName(e.target.value)}
                                        onBlur={validation.handleBlur}
                                        value={name || ""}
                                        invalid={
                                            validation.touched.username && validation.errors.username ? true : false
                                        }
                                    />
                                    {validation.touched.username && validation.errors.username ? (
                                        <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="text-center mt-4">
                                    <Button type="submit" color="danger">
                                        Update User Name
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(UserProfile);
