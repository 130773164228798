import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    Input,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import {withTranslation} from "react-i18next"

//redux
import {useSelector, useDispatch} from "react-redux"
import {Link} from "react-router-dom"
import {show} from "../../../redux/reducers/notification";
import Spinner from "../../../components/Common/Spinner";

//Pages components
import MonthlyEarning from "./MonthlyEarning";

const AdminDashboard = props => {
    //meta title
    document.title = "Roundtable | Admin/Dashboard"

    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.auth.currentUser);

    const [isModal, setIsModal] = useState(false);
    const [rooms, setRooms] = useState([]);
    const [isLoading, setIsLoading] = useState(true);


    const options = [
        {value: "Finished", label: "Finished"},
        {value: "Live", label: "Live"},
        {value: "Not Started", label: "Not Started"}
    ]

    const handleOpenModal = () => {
        setIsModal(true);
    }

    useEffect(() => {
        setIsLoading(false);
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs title={"Admin"} breadcrumbItem={"Dashboard"}/>
                    {isLoading ? <Spinner/> :
                        <>
                            <Row>
                                <Col xl="4">
                                    <MonthlyEarning/>
                                </Col>
                                <Col xl="8">
                                    <Row>
                                        <Col md="4" >
                                            <Card className="mini-stats-wid">
                                                <CardBody>
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1">
                                                            <p className="text-muted fw-medium">
                                                                Revenue
                                                            </p>
                                                            <h4 className="mb-0">$1,235</h4>
                                                        </div>
                                                        <div
                                                            className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                            <span className="avatar-title rounded-circle bg-primary">
                                                              <i className={"bx bx-user-check font-size-24"}/>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col md="4" >
                                            <Card className="mini-stats-wid">
                                                <CardBody>
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1">
                                                            <p className="text-muted fw-medium">
                                                                Request Amount
                                                            </p>
                                                            <h4 className="mb-0">1,235</h4>
                                                        </div>
                                                        <div
                                                            className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                            <span className="avatar-title rounded-circle bg-primary">
                                                              <i className={"bx bx-user-check font-size-24"}/>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col md="4" >
                                            <Card className="mini-stats-wid">
                                                <CardBody>
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1">
                                                            <p className="text-muted fw-medium">
                                                                Subscribed
                                                            </p>
                                                            <h4 className="mb-0">1,235</h4>
                                                        </div>
                                                        <div
                                                            className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                            <span className="avatar-title rounded-circle bg-primary">
                                                              <i className={"bx bx-user-check font-size-24"}/>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            {/* Roundtable list */}
                        </>
                    }
                    {/* Filter */}

                </Container>

            </div>
        </React.Fragment>
    )
}

AdminDashboard.propTypes = {
    t: PropTypes.any,
}

export default withTranslation()(AdminDashboard)
