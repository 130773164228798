import {configureStore} from "@reduxjs/toolkit";
import authReducer from "./reducers/auth";
import layoutReducer from "./reducers/layout";
import notificationReducer from "./reducers/notification";

export const store = configureStore({
    reducer: {
        notification: notificationReducer,
        auth: authReducer,
        layout: layoutReducer,
    }
});