import React, {useEffect, useState} from "react";
import {Button, Card, CardBody, Col, Collapse, Form, Input, Label, Row} from "reactstrap";
import {useDispatch} from "react-redux";
import {
    addParticipant,
    deleteParticipant,
    getAllParticipants,
    getParticipants,
    updateParticipant
} from "../../../services/admin";
import {show} from "../../../redux/reducers/notification";

export const Participants = () => {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState('0');
    const [participants, setParticipants] = useState([]);

    const [currentParticipant, setCurrentParticipant] = useState({
        name: '',
        role: '',
        functions: '',
        description: '',
    });

    const toggle = (id) => {

        if (isOpen === id) {
            setIsOpen('0');
            setCurrentParticipant({
                name: '',
                role: '',
                functions: '',
                description: '',
            });
        } else {
            setIsOpen(id);
            if (id === '1') {
                setCurrentParticipant({
                    name: '',
                    role: '',
                    functions: '',
                    description: '',
                });
            }
            for (let i = 0; i < participants.length; i++) {
                if (participants[i]._id === id) {
                    setCurrentParticipant(participants[i]);
                    break;
                }
            }
        }
    }

    const handleFormChange = (e) => {
        const {name, value} = e.target;
        if (name === "functions") {
            setCurrentParticipant({...currentParticipant, [name]: value});
        } else {
            setCurrentParticipant({...currentParticipant, [name]: value});
        }
    }

    const handleParticipantConfig = async (e) => {
        try {
            const res = await addParticipant(currentParticipant);
            dispatch(show({title: res.message, body: "", type: 'success'}))
            if (res.status === 'success') {
                await fetchParticipants();
                toggle('0');
            }
        } catch (e) {
            console.log('e', e.response.data);
            dispatch(show({title: e.response.data.message, body: "", type: 'error'}))
        }
    }

    const removeParticipant = async (id) => {
        try {
            const res = await deleteParticipant(id);
            if (res.status === 'success') {
                dispatch(show({title: res.message, body: "", type: 'success'}))
                await fetchParticipants();
            }
        } catch (e) {
            console.log('e', e.response.data);
            dispatch(show({title: e.response.data.message, body: "", type: 'error'}))
        }
    }

    const updateParticipantConfig = async (id) => {
        try {
            const res = await updateParticipant(id, currentParticipant);
            console.log('res', res)
            if (res.status === 'success') {
                dispatch(show({title: res.message, body: "", type: 'success'}))
                await fetchParticipants();
                toggle('0');
            }
        } catch (e) {
            console.log('e', e.response.data);
            dispatch(show({title: e.response.data.message, body: "", type: 'error'}))
        }
    }

    const fetchParticipants = async () => {
        const res = await getAllParticipants();
        if (res) {
            console.log('res', res);
            setParticipants(res.data);
        }
    }

    useEffect(() => {
        fetchParticipants();
    }, []);
    return (
        <React.Fragment>
            <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <h4 className="card-title mb-1 d-flex justify-content-between align-items-center">
                                AI Participants
                                <a href="#" onClick={() => toggle('1')} className="btn btn-outline-success">
                                    <i className="bx bx-plus-circle align-middle"/> Add New
                                </a>
                            </h4>
                            <div className="wizard clearfix">
                                <div className={`${isOpen !== '0' && 'border'} p-2 `}>
                                    <Collapse isOpen={isOpen !== '0'} id="betConfig-collapse">
                                        <Form onSubmit={(e) => {
                                            e.preventDefault();
                                            if (isOpen === '1') {
                                                handleParticipantConfig();
                                            } else {
                                                updateParticipantConfig(isOpen);
                                            }
                                        }}>
                                            <Row>
                                                <Col lg="6">
                                                    <div className="mb-3">
                                                        <Label for="participant-name">
                                                            Name
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="participant-name"
                                                            name="name"
                                                            placeholder=""
                                                            value={currentParticipant.name || ""}
                                                            onChange={(e) => {
                                                                handleFormChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg="6">
                                                    <div className="mb-3">
                                                        <Label for="role">
                                                            Role
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="role"
                                                            name="role"
                                                            placeholder=""
                                                            value={currentParticipant.role || ""}
                                                            onChange={(e) => {
                                                                handleFormChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="12">
                                                    <div className="mb-3">
                                                        <Label for="system-message">
                                                            System Message
                                                        </Label>
                                                        <textarea
                                                            className="form-control"
                                                            id="system-message"
                                                            name="functions"
                                                            rows={5}
                                                            placeholder={''}
                                                            value={currentParticipant.functions || ""}
                                                            onChange={(e) => {
                                                                handleFormChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg="12">
                                                    <div className="mb-3">
                                                        <Label for="agent-description">
                                                            Agent Description
                                                        </Label>
                                                        <textarea
                                                            className="form-control"
                                                            id="agent-description"
                                                            name="description"
                                                            rows={5}
                                                            placeholder={''}
                                                            value={currentParticipant.description || ""}
                                                            onChange={(e) => {
                                                                handleFormChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg="12">
                                                    <div className="">
                                                        <Button type={"submit"} color="primary">
                                                            {isOpen === '1' ? 'Create' : 'Update'}
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Collapse>
                                </div>
                            </div>
                            <Row>
                                <Col lg="12">
                                    <ul className="list-unstyled mb-0">
                                        {participants && participants.map((participant, index) => (
                                            <li key={index}
                                                className="d-flex justify-content-between align-items-center border rounded-pill px-4 py-1 mt-2">
                                                <div>
                                                    <h5 className="mb-0">{participant.name}</h5>
                                                </div>
                                                <div>
                                                    <p className="mb-0">{participant.role}</p>
                                                </div>
                                                <div className={''}>
                                                    <a className="btn btn-outline-success btn-sm mx-1"
                                                       onClick={() => toggle(participant._id)}>
                                                        <i className="bx bx-edit-alt mr-1"/>
                                                    </a>
                                                    <a className="btn btn-outline-danger btn-sm mx-1"
                                                       onClick={() => removeParticipant(participant._id)}>
                                                        <i className="bx bx-trash-alt mr-1"/>
                                                    </a>
                                                </div>
                                            </li>
                                        ))}

                                    </ul>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}