import {createSlice} from "@reduxjs/toolkit";
import {
    layoutModeTypes,
    layoutTypes,
    layoutWidthTypes,
    leftBarThemeImageTypes,
    leftSideBarThemeTypes,
    leftSidebarTypes,
    topBarThemeTypes
} from "../../constants/layout";

const initialState = {
    layoutType: layoutTypes.VERTICAL,
    layoutModeType: layoutModeTypes.LIGHT,
    layoutWidth: layoutWidthTypes.FLUID,
    leftSideBarTheme: leftSideBarThemeTypes.DARK,
    leftSideBarThemeImage: leftBarThemeImageTypes.NONE,
    leftSideBarType: leftSidebarTypes.DEFAULT,
    topbarTheme: topBarThemeTypes.LIGHT,
    isPreloader: false,
    showRightSidebar: false,
    isMobile: false,
    showSidebar: true,
    leftMenu: false,
}

export const slice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        changeLayout(state, action) {
            state.layoutType = action.payload;
        },
        changePreloader(state, action) {
            state.isPreloader = action.payload;
        },
        changeLayoutMode(state, action) {
            state.layoutModeType = action.payload;
        },
        changeLayoutWidth(state, action) {
            state.layoutWidth = action.payload;
        },
        changeSidebarTheme(state, action) {
            state.leftSideBarTheme = action.payload;
        },
        changeSidebarThemeImage(state, action) {
            state.leftSideBarThemeImage = action.payload;
        },
        changeSidebarType(state, action) {
            state.leftSideBarType = action.payload.sidebarType;
        },
        changeTopbarTheme(state, action) {
            state.topbarTheme = action.payload;
        },
        showRightSidebar(state, action) {
            state.showRightSidebar = action.payload;
        },
        showSidebar(state, action) {
            state.showSidebar = action.payload;
        },
        toggleLeftmenu(state, action) {
            state.leftMenu = action.payload;
        }
    }
});

export const {
    changeLayout,
    changePreloader,
    changeLayoutMode,
    changeLayoutWidth,
    changeSidebarTheme,
    changeSidebarThemeImage,
    changeSidebarType,
    changeTopbarTheme,
    showRightSidebar,
    showSidebar,
    toggleLeftmenu
} = slice.actions;

export default slice.reducer;